.investing--font {
  font-size: 40px;
  font-family: "Montserrat";
  font-weight: 700;
}

.investing--span {
  background: linear-gradient(
    90deg,
    rgba(25, 176, 230, 1) 13%,
    rgba(47, 127, 215, 1) 34%,
    rgba(25, 175, 229, 1) 62%,
    rgba(69, 77, 201, 1) 86%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.investing--subText,
.investing--content {
  font-family: "Poppins";
  font-size: 16px;
  color: rgba(0, 32, 96);
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: rgba(0, 32, 96) !important;
}

.panel--body {
  font-family: "Poppins";
  font-size: 16px;
  color: rgba(0, 32, 96);
}

.panel--table {
  font-family: "Poppins";
  font-size: 16px;
  color: rgba(0, 32, 96);
}

.panel--table td {
  padding: 0 !important;
}

.invest--online .table td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 740px) {
  .invest--vplayer {
    width: 295px !important;
    height: 195px !important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 13px !important;
    padding: 12px 35px 12px 12px;
  }
}
