.newCreate--form-wrapper {
  position: relative;
  width: 100%;
  max-width: 1000px;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.guide--height {
  padding-top: 2.5rem;
}

.kyc-form--title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  margin: 0 0 5px 0;
}

.kyc-form--text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  margin: 0 0 5px 0;
}

.kyc-form--sub {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  text-align: left;
}

.kyc--steps .ant-steps-item-title {
  line-height: 1.3em;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

.kyc--steps .ant-steps-item-icon {
  font-size: 12px;
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.kyc--steps .ant-steps-item-process .ant-steps-item-icon {
  background: #062d97 !important;
  border-color: #062d97 !important;
}

.icon-container {
  background: #dfe1e3;
  font-size: 16px;
  color: #062d97;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px 0 0 5px;
}

.text-container {
  background: #e4eef8;
  color: #062d97;
  padding-top: 7px;
  padding-bottom: 5px;
  padding-left: 20px;
  border-radius: 0 5px 5px 0;
  width: 100%;
}

.text-container p {
  font-family: "Poppins";
  font-size: 14px !important;
}

.newKYC--form .MuiFormLabel-root {
  font-size: 12px !important;
}

.newKYC--form .MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.54) !important;
}

.newKYC--form
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #007ae2 !important;
  border-width: 2px;
}

.newKYC--form .MuiFormLabel-root.Mui-focused {
  color: gray !important;
}

.newKYC--form .MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1);
}

.newKYC--form .MuiTypography-body1,
.newKYC--form .fatca-q {
  font-family: "Poppins" !important;
  font-size: 13px !important;
}

.newKYC--form .flatInput {
  margin-top: 8px;
  width: 100%;
  max-width: 100%;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #c3c3c3;
  height: 37px;
}

.newKYC--form .flatInput::placeholder {
  color: #0000008a;
  font-weight: 400;
  padding-left: 4px;
}

.newKYC--form .ant-checkbox-wrapper {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.MuiInputBase-input {
  font-family: "Poppins" !important;
  padding: 10px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #000 !important;
  height: 1.4em !important;
}

.account-btn-next,
.account-btn-back,
.account-btn-back[disabled],
.account-btn-next[disabled] {
  background: #fff0 !important;
  border-color: #d9d9d900 !important;
}

.account-btn-next.ant-btn:hover,
.account-btn-back.ant-btn:hover,
.account-btn-next.ant-btn:focus,
.account-btn-back.ant-btn:focus {
  color: #062d97 !important;
  border-color: #40a9ff00 !important;
  background: #fff0 !important;
}

.account-btn-next,
.account-btn-back {
  display: flex !important;
  font-size: 12px !important;
  vertical-align: middle !important;
}

.stepper-type .MuiMobileStepper-dot {
  border-radius: 0 !important;
}

.stepper-type .MuiMobileStepper-root {
  padding: 4px !important;
}

.stepper-type svg {
  font-size: 18px;
}

.account--btn {
  font-family: "Poppins" !important;
  font-size: 12px !important;
  background: rgb(94, 155, 240) !important;
  background: linear-gradient(
    90deg,
    rgba(94, 155, 240, 1) 0%,
    rgba(127, 206, 255, 1) 81%
  ) !important;
  border: 0px solid #fff !important;
  color: #fff !important;
}

.upload-img-id {
  width: 100px;
}

.upload-kyc {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.submit--summary .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
}

.submit--summary table {
  border-collapse: collapse;
}

.submit--summary .table-bordered {
  border: 1px solid #dee2e6;
}

.submit--summary td,
.submit--summary th {
  border: 1px solid #dee2e6;
  padding: 0.5rem;
  vertical-align: top;
}

.submit--summary th {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.submit--summary td {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.modal-footer {
  padding: 0.7rem !important;
}

.email-modal-wrapper .modal-title {
  font-size: 14px !important;
  color: #fff !important;
}

.terms-title2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 26px !important;
  margin: 30px 0 30px 0;
}

.email-modal-wrapper .modal-header {
  padding: 1rem !important;
}

.terms-text-cjm {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

/* fatca */
.helper--fatca {
  font-size: 11px !important;
  color: gray;
  margin-left: 3.5rem;
  margin-top: 0.25rem;
}

.fatca-radio .MuiFormGroup-root {
  display: flex !important;
  flex-wrap: nowrap;
  flex-direction: unset;
}

.fatca-radio .MuiFormControlLabel-root {
  margin-left: 20px !important;
}

.fatca--radio-float {
  margin-left: auto !important;
  margin-right: 90px !important;
}

.newKYC--form .MuiTypography-body1,
.newKYC--form .fatca-q {
  font-family: "Poppins" !important;
  font-size: 13px !important;
}

.radio-yes .PrivateSwitchBase-root-39 {
  padding: 0 5px 0 0 !important;
}

.submit--summary .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
}

.submit--summary table {
  border-collapse: collapse;
}

.submit--summary .table-bordered {
  border: 1px solid #dee2e6;
}

/* Error Message */
.error--kyc {
  font-size: 12px;
  font-family: "Poppins";
  color: #f44336;
  /*margin-left: 14px;
  margin-right: 14px;*/
}

.helper--fatca {
  font-size: 11px !important;
  color: gray;
  margin-left: 3.5rem;
  margin-top: 0.25rem;
}

@media (max-width: 740px) {
  /* .sm--form-wrapper {
    padding-top: 0 !important;
  } */

  .kyc--accountType.ant-card-bordered {
    border: 0px solid #fff !important;
  }

  .kyc--accountType .ant-card-body {
    padding-top: 15px !important;
  }

  .kyc--desktop {
    display: none !important;
  }

  .body--top {
    padding-top: 0.5rem !important;
  }

  .joint--top {
    padding-top: 0 !important;
  }

  .hide--mobile {
    display: none !important;
  }

  .signature-radio-sm {
    margin-left: 0 !important;
  }

  .upload-sm-center {
    margin-left: 4rem !important;
  }

  .signature--btn-sm {
    margin-top: 1rem !important;
  }

  .margin-top-submit {
    margin-top: 0 !important;
  }

  .width-summary {
    width: 50% !important;
  }
}
