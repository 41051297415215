.nvestmo-bg {
  background: url("~assets/img/affinity/nvestmo--bg.png") 50% fixed !important;
  background-repeat: no-repeat !important;
  /* background-position: 50% fixed !important; */
  background-size: cover !important;
  z-index: 1 !important;
  overflow: visible;
}

.nvestmo--img {
  width: 60%;
}

.section--logo {
  max-width: 23%;
  height: 34px;
}

.section--logo2 {
  max-width: 23%;
  height: 34px;
}

.text--logo {
  height: 18px;
}

.invest-img {
  width: 60%;
}

.fs-img {
  width: 50%;
}

.view-fundImg {
  width: 80%;
}

.vector--img {
  max-width: 50%;
}

.why--img {
  width: 90%;
}

.s2--title {
  font-family: "Poppins";
  font-size: 30px !important;
  font-weight: 500;
  color: #fff;
}

.s2--text {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.border-section {
  display: flex;
  justify-content: center;
  max-width: 300px;
  border-left: 5px solid #fff;
  padding-left: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.section2--bg {
  background-color: #8cbc4c;
  padding: 3rem;
}

.section3--container {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 20px;
}

.s3--circle {
  background-color: #8cbc4c;
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 500;
  border-radius: 50px;
  max-width: 50px;
  text-align: center;
  color: #fff;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}

.s3--subTitle {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #062d97;
}

.s3--text {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.what--section {
  background: url("../../../img/affinity/column-bg.webp") 50% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  z-index: 1 !important;
  overflow: visible;
}

.nvestmoBanner--title {
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 500;
  text-align: left;
  margin: 0;
  width: 85%;
  /* height: -60px !important; */
  /* overflow: hidden !important; */
}

.nvestmoSub--title {
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 600;
  color: #062d97;
}

.nvestmo--text {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  color: #062d97 !important;
}

.nvestmo--newText {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: #000 !important;
}

.nvestmo--subText {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #000 !important;
}

.text-main-nvestmo {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: #007ae2;
}

.text-main-flex {
  display: flex;
  align-items: center;
  align-content: center;
}

.bannerNvestmo--pad {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.nvestmo-left {
  border-left: 0.0625rem solid #062d97 !important;
}

.nvestmo-right {
  border-right: 0.0625rem solid #062d97 !important;
}

.sm-rows {
  width: 90%;
}

.s2-mobile {
  display: none;
}

/* new design */
.nvestmo-wealth-bg {
  background: #dbdcde;
  margin-bottom: 3rem;
}

.nvestmo-last-bg {
  background: #bdd1ec;
}

.main-nvestmo-container {
  margin-top: 4rem;
}

.nvestmo-wealth-title {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 700;
}

.nvestmo-wealth-text {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
}

.nvestmo-wealth-text2 {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
}

.nvestmo-text-icon {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  padding-right: 5rem;
}

.nvestmo-sub-icon {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
}

.nvestmo-section-text {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
}

.nvestmo-section-ul {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.nvestmo-section-sub {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.nvestmo-button {
  display: flex;
  justify-content: center;
  font-family: "Poppins" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #062d97 !important;
  background: linear-gradient(
    93.47deg,
    #a8e02d 21.48%,
    #8cbd4c 71.59%
  ) !important;
  border: 0px solid transparent !important;
  text-transform: unset !important;
}

.bottom-section {
  margin-top: 3rem;
}

.last-wrapper-top {
  margin-top: 3.5rem;
}

.logo-btn {
  width: 31%;
  height: auto;
}

.bottom-nvestmo {
  margin-top: 3rem;
}

.text-nvestmo-wrapper svg {
  vertical-align: top !important;
  height: max-content !important;
}

.nvestmo-icon {
  font-size: 22px !important;
  margin-right: 15px;
  color: #8cbc4c;
}

.nvestmo-dark {
  color: #062d97 !important;
}

.nvestmo-blue-light {
  color: #0052e2 !important;
}

.nvestmo-section-padd {
  padding: 3rem 5rem 0 5rem;
}

.nvestmo-section-img {
  text-align: left;
}

.wealth-img {
  position: relative;
  max-width: 100%;
}

.main-section {
  margin-top: 3rem;
}

.text-nvestmo-wrapper {
  display: flex;
}

.nvestmo-card-container {
  margin-top: 2rem;
}

.nvestmo-details-wrapper {
  margin-top: 3rem;
}

.nvestmo-card {
  background: #dbdcde !important;
  border-radius: 29px !important;
  border: 0 solid #fff !important;
}

.section3-wrapper {
  display: flex;
}

.nvestmo-details-number {
  width: 90%;
}

.nvestmo-wrapper-card {
  margin-bottom: 3rem;
}

.nvestmo-shape {
  width: 100px;
  border-top: 0.5rem solid #ff8e00 !important;
}

.nvestmo-shape-last {
  width: 100%;
  border-top: 0.5rem solid #ff8e00 !important;
}

.nvestmo-wealth {
  margin-top: 3rem;
}

.nvestmo-text-logo {
  margin-left: 1rem;
}

.section-flex {
  display: flex;
}

@media (max-width: 740px) {
  .s2-mobile {
    display: flex !important;
  }

  .s2-desk {
    display: none !important;
  }

  .why--img {
    width: 135px !important;
    height: 133px !important;
    object-fit: cover !important;
    object-position: -100px 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .s2--title {
    font-size: 24px !important;
  }

  .s2--sm-font {
    font-size: 16px !important;
  }

  .s2--text {
    font-size: 12px !important;
  }

  .section2--bg {
    padding: 1rem !important;
    margin-top: 0 !important;
  }

  .nvestmoBottom {
    margin-bottom: 0rem !important;
  }

  .nvestmo--subText-sm {
    font-size: 16px !important;
    width: 100% !important;
  }

  .nvestmoSub--title {
    font-size: 30px !important;
  }

  .nvestmo--newText {
    font-size: 18px !important;
  }

  .nvestmo--subText {
    font-size: 15px !important;
    text-align: center !important;
  }

  .sm-rows {
    width: 110% !important;
    margin-right: 0 !important;
  }

  .get-started {
    text-align: center !important;
  }

  .step-left {
    margin-left: 0.5rem !important;
  }

  .nvestmo-left {
    border-left: 0 solid #062d9700 !important;
  }

  .nvestmo-right {
    border-right: 0 solid #062d9700 !important;
  }

  .nvestmo--smTop {
    margin-top: 2rem !important;
  }

  .nvestmo--addPad {
    margin-top: 1rem !important;
  }

  .nvestmo--resetPad {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .nvestmo-top {
    margin-top: 4rem !important;
  }

  .nvestmo--resetTop {
    margin-top: 0 !important;
  }

  .reset--top-sm {
    margin-top: 3rem !important;
  }

  .section--logo {
    max-width: 45% !important;
    height: 30px !important;
    margin-left: 0.5rem !important;
    margin-top: 4px !important;
  }

  .section--logo2 {
    max-width: 45% !important;
    height: 30px !important;
    margin-left: 0.5rem !important;
    margin-top: -9px !important;
  }

  .invest-img {
    width: 50% !important;
  }

  .redeem-img {
    width: 80% !important;
  }

  .fs-img {
    width: 40% !important;
  }

  .view-fundImg {
    width: 60% !important;
  }

  .reset--bottom-sm {
    margin-bottom: 3rem !important;
  }

  .nvestmo--img-sm {
    width: 70% !important;
  }

  .nvestmo--sub {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .nvestmo--steps {
    margin-top: 1rem !important;
  }

  .what--section {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .nvestmo-top {
    margin-top: 2rem !important;
  }

  .nvestmo-title-top {
    margin-top: 3rem;
  }

  .text-main-flex {
    display: unset !important;
    width: 100% !important;
  }

  .nvestmo-vid {
    margin-top: 3rem !important;
  }

  .nvestmo-section-padd {
    padding: 3rem 1rem 3rem 1rem !important;
  }

  .nvestmo-section-details {
    margin-top: 3rem !important;
  }

  .text-nvestmo-wrapper {
    margin-bottom: 2rem !important;
  }

  .nvestmo-wealth {
    margin-top: 0rem !important;
  }

  .nvestmo-card {
    height: 100% !important;
  }

  .nvestmo-card-top {
    margin-bottom: 2rem !important;
  }

  .nvestmo-details-wrapper {
    margin-top: 0rem !important;
  }

  .nvestmo-text-logo {
    margin-left: 0 !important;
  }

  .nvestmo-sm-padding {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .nvestmo-last-top {
    margin-top: 3rem !important;
  }

  .logo-btn {
    width: 30% !important;
  }

  .last-wrapper-top {
    margin-top: 2rem !important;
  }

  .nvestmo-container-section {
    margin-top: 2rem !important;
  }

  .bottom-section {
    margin-top: 2rem !important;
  }

  .nvestmo-card-size .card-body {
    padding: 0.8rem !important;
  }

  .section-flex {
    display: block !important;
  }

  .nvestmo-text-icon {
    padding-right: 0 !important;
  }

  .section3-wrapper {
    margin-bottom: 15px !important;
  }

  .nvestmo-wrapper-card {
    margin-bottom: 1.5rem !important;
  }

  .main-nvestmo-container {
    margin-top: 2rem !important;
  }

  .vector--img {
    max-width: 30% !important;
  }

  .s3--subTitle {
    font-size: 18px !important;
  }

  .nvestmo-text-icon {
    font-size: 18px !important;
  }

  .nvestmo-shape {
    display: none !important;
  }
}
