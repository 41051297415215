.forms--font {
  font-size: 40px;
  font-family: "Montserrat" !important;
  font-weight: 700;
}

.forms--title {
  background: linear-gradient(
    90deg,
    rgba(25, 176, 230, 1) 13%,
    rgba(47, 127, 215, 1) 34%,
    rgba(25, 175, 229, 1) 62%,
    rgba(69, 77, 201, 1) 86%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.subText {
  font-size: 13px;
}

.forms--text {
  font-size: 18px;
  color: #000;
}

.dlBtn--size {
  width: 16%;
}

.forms--cardText {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 2rem;
  color: #062d97;
}

.cards--bg {
  background-color: #f4fcff !important;
}

.circle--number {
  background-color: #062d97;
  border-radius: 50%;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  color: #fff;
}

.table--forms p,
.table--forms th {
  font-size: 13px !important;
  color: #000;
  border-color: #000;
}

.table--forms td {
  font-size: 12px !important;
  color: #000;
  border-color: #000;
  padding: 0.7rem !important;
}

.table--forms thead th {
  border-bottom: 0.1rem solid #000;
  width: 305px;
}

.td--title {
  font-weight: 700;
  color: #062d97 !important;
  margin-top: 0.8rem;
}

.guide--text {
  font-family: "Poppins" !important;
  font-weight: 600;
  font-size: 14px;
  color: #062d97;
}

.form--text {
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.guide--ul {
  font-family: "Poppins" !important;
  font-size: 12px;
  color: #062d97;
}

.email--span {
  color: #062d97;
  font-weight: 700;
}

.mobileForms--view {
  display: none !important;
}

.panelForms--title-desc {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #062d97;
}

.panelForms--desc {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: #062d97;
}

/* new styles */
.ml-1rem {
  margin-left: -1rem;
}

/* New Design forms */
.DLforms--wrapper {
  padding: 1rem;
  align-self: center;
  align-items: center;
}

.DLforms--wrapper:hover {
  box-shadow: 0px 8px 16px rgb(198 198 198 / 30%);
  transition: all 0.2s ease-in-out;
  z-index: 1;
  color: #ff8e00 !important;
}

.DLforms--space {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}

.DLforms--icon {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  font-size: 2.5rem;
  font-weight: 500;
  color: #062d97;
}

.DLform--text {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  color: #062d97;
  text-align: left;
}

.DLform--text:hover {
  color: #ff8e00;
  transition: all 0.2s ease-in-out;
}

.DLforms--btn {
  padding: 0.6rem;
  border: 0 !important;
  border-radius: 0 !important;
  color: #fff;
  background-color: #062d97 !important;
  border-color: #062d97 !important;
  font-size: 12px !important;
  min-height: 2rem !important;
}

.DLforms--btn:hover {
  background-color: #ff8e00 !important;
  border-color: #ff8e00 !important;
  transition: all 0.2s ease-in-out;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .affinity--forms .card-deck .card {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .affinity--forms .card-body {
    padding: 1rem !important;
  }

  .forms-tablet {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .forms--cardText {
    font-size: 14px !important;
  }

  .guide--ul-tablet {
    margin-left: 1rem !important;
  }

  .forms-order-sm1 {
    order: 1 !important;
  }

  .forms-order-sm2 {
    order: 2 !important;
  }

  .fluid-px-reset {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media (max-width: 740px) {
  .forms--font {
    font-size: 24px !important;
    margin-top: 1rem !important;
  }

  .forms-padding {
    padding: 0rem !important;
  }

  .forms-ul-sm {
    margin-left: 0 !important;
  }

  .title-container {
    padding-top: 4rem;
  }

  /* Panel */
  .mobileForms--view .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    /* background: #002060 !important; */
    background: #e3eef8 !important;
    color: #062d97 !important;
    font-size: 14px !important;
  }

  .mobileForms--view {
    display: block !important;
  }

  .largeForms--view {
    display: none !important;
  }

  .forms--text {
    font-size: 16px !important;
  }

  .subText {
    font-size: 12px !important;
  }

  .dlBtn--size {
    width: 8% !important;
  }

  .forms--cardText {
    font-weight: 600 !important;
    margin-bottom: 1rem !important;
  }

  .guide--ul {
    font-size: 13px !important;
  }

  .guideSM--text {
    font-size: 14px !important;
  }

  .guide--text {
    font-size: 14px !important;
  }

  .forms-order-sm1 {
    order: 1 !important;
  }

  .forms-order-sm2 {
    order: 2 !important;
  }

  .fluid-px-reset {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .forms-reset-top {
    margin-top: 0rem !important;
  }

  .forms-sm-top {
    margin-top: 2rem !important;
  }

  .DLform--text {
    font-size: 13px !important;
    width: 150px !important;
  }

  .DLforms--wrapper {
    padding: 0.3rem !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .formTitle-tablet {
    margin-top: 3rem !important;
  }
}
