.payment--icon-color {
  color: #005cb1;
  /* background-color: rgba(24, 144, 255, 0.22); */
  background-color: #fff !important;
  border: 0.8px solid #0076e4 !important;
}

.payment--bg {
  background: transparent !important;
  cursor: pointer;
}

.paymentNew--bg {
  background: #f4fcff !important;
}

.payment .modal-header {
  background: #0076e4 !important;
  padding: 0.8rem !important;
}

.payment .modal-title {
  font-size: 16px !important;
  color: #fff !important;
}

.payment .modal-footer {
  padding: 0.8rem !important;
}

.payment--card {
  font-family: "Poppins" !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  text-transform: uppercase;
  text-align: center !important;
}

.payment--title-color {
  color: #062d97 !important;
}

.icon-size {
  width: 11%;
  height: 7%;
}
.icon-size-other {
  height: 1.5rem;
}
.payment--cardText {
  font-family: "Poppins";
  font-size: 14px;
  color: #062d97;
  font-weight: 500;
}

.text-bills {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  /*color: #062d97;*/
  color: #000 !important;
}

.text-bills-info {
  font-family: "Poppins";
  font-size: 13px;
  /*color: #062d97;*/
  color: #000 !important;
  font-weight: 500;
}

.text-bills-steps {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  /*color: #062d97;*/
  color: #000 !important;
}

.payment_ul {
  margin-left: 3.4rem;
}

.payment-img-size {
  /* width: 182px; */
  height: 130px;
}

.payment--title {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.other--img {
  height: 40px;
}

.transaction--img {
  height: 384px;
}

.payment--methodText {
  font-size: 24px;
  font-weight: 700;
}

.body-mode {
  font-family: "Poppins";
  font-size: 16px;
  color: #062d97;
}

.payment--option {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  color: #062d97;
}

.payment--step {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  /* color: #062d97; */
  color: #000;
}

.payment--list {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  /* color: #062d97; */
  color: #000;
}
.text-dec-none {
  list-style: none;
}
.address {
  margin-left: 4rem;
}

.adjust--left {
  margin-left: 8.5rem;
}

.bpi-online dt {
  line-height: 1.5 !important;
}

.border--payment {
  border-top: 1px solid #a3a2a2 !important;
}

.border--payment2 {
  border-top: 1px solid #a3a2a2 !important;
}

.check {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  /*background-color: #fafafa;
  border: 1px solid #d9d9d9;*/
  border-radius: 2px;
  padding-top: 12px;
  padding-bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  border-bottom: 1px solid #d9d9d9 !important;
}

.check2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*background-color: #fafafa;
  border: 1px solid #d9d9d9;*/
  border-radius: 2px;
  padding-top: 12px;
  padding-bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
  border-bottom: 1px solid #d9d9d9 !important;
}

.check-text-new {
  display: flex !important;
  align-items: center !important;
  align-content: center !important;
  align-self: center !important;
  font-family: "Poppins";
  font-size: 16px;
  color: rgba(0, 32, 96) !important;
}

.check-title {
  font-family: "Poppins";
  font-size: 16px;
  color: rgba(0, 32, 96) !important;
}

.extra-text {
  font-family: "Poppins";
  font-size: 16px;
  color: #8f8f8f !important;
}

.remit-list {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #464646 !important;
  margin-left: 2rem;
}

.remit-list p {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #464646 !important;
}

.remit-list li {
  cursor: pointer;
}

.remit-link {
  display: inline;
  position: relative;
  overflow: hidden;
}

.remit-link::after {
  transition: 300ms;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #ee8e18;
  left: 0;
  width: 0;
  bottom: -3px;
}

/* .remit-type::before {
  width: 0%;
  bottom: -2px;
} */

.remit-link:hover::after {
  width: 100%;
}

.remit-link:hover {
  color: #062d97 !important;
}

.payment-deposit .ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9 !important;
}

.payment-deposit
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 14px !important;
}

.payment-table-new th {
  font-size: 12px !important;
  font-weight: 400;
  color: #000 !important;
}

.payment-table-new td {
  font-size: 11px !important;
  font-weight: 400;
  padding: 0.3rem !important;
  color: #000 !important;
}

.reset-dt {
  padding-left: 0;
  padding-right: 0;
}

.fee-text {
  font-family: "Poppins";
  font-size: 12px;
  color: #000;
}

.fee-border {
  border-left: 5px #0076e4 solid !important;
  border-radius: 5px !important;
}

.payment-fee-sm {
  display: none;
}

.display-mbody-sm {
  display: none;
}

.hr-payment {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .over-tablet {
    margin-left: 0 !important;
  }

  .paymentStep-tablet {
    white-space: nowrap !important;
  }
}

@media (max-width: 740px) {
  .sm-font {
    font-size: 24px !important;
  }

  .mbody .modal-body {
    padding: 1rem !important;
  }

  .mbody .payment_ul {
    margin-left: 2rem !important;
  }

  .payment-fee-sm {
    display: block !important;
  }

  .payment-fee-lg {
    display: none !important;
  }

  .card-sm-size {
    width: 100% !important;
  }

  .icon-size {
    width: 25%;
  }

  .sm-left {
    padding-left: 15px !important;
  }

  .ada-left {
    margin-left: 0 !important;
  }

  .address {
    margin-left: 1rem !important;
  }

  .sm-top {
    margin-top: 1rem !important;
  }

  .reset-left {
    padding-left: 0 !important;
  }

  .mobile {
    margin-top: 1rem !important;
    width: 100% !important;
  }

  .affinity-portion {
    width: 100% !important;
  }

  .display-sm {
    display: block !important;
  }

  .stepSM-bottom {
    margin-bottom: 1rem !important;
  }

  .stepSM-left {
    margin-left: 0.5rem !important;
  }

  .card-left {
    margin-left: 0 !important;
  }

  .check-left {
    margin-left: 2rem !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .paymentSM--title {
    margin-bottom: 0 !important;
  }

  .ada--padding {
    padding-top: 5px !important;
    padding-bottom: 0 !important;
  }

  .border--payment {
    border-top: 0px solid #000 !important;
  }

  .payment--sm-left {
    margin-left: 0.8rem !important;
  }



  .extra-text {
    font-size: 13px !important;
  }

  .check-title,
  .check-title-new,
  .check-text-new,
  .check-text-new {
    font-size: 13px !important;
  }

  .mobile-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .check {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .remit-list {
    margin-left: 0 !important;
  }

  .payment .modal-title {
    font-size: 14px !important;
  }

  .ada-payment-sm {
    padding-left: 15px !important;
  }

  .payment-width {
    display: table-cell !important;
    width: 50px !important;
  }

  .mbody-left2 {
    padding-left: 13px !important;
  }

  .mbody-left {
    padding-left: 10px !important;
  }

  .display-mbody-lg {
    display: none !important;
  }

  .display-mbody-sm {
    display: block !important;
  }

  /* .dt-sm-steps {
    margin-right: 12px;
  } */

  .reset-payment {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .reset-dt {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .ada-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
