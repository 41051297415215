.what-we {
  background: rgb(224, 233, 243);
  background: linear-gradient(
    180deg,
    rgba(224, 233, 243, 1) 40%,
    rgba(220, 230, 241, 0.9668242296918768) 74%,
    rgba(249, 250, 252, 31%) 100%
  );
}

.bg-light-what {
  position: relative;
  background: #fff !important;
  background-size: contain;
  max-width: 96rem !important;
  left: 10rem !important;
}

.bg-dark-what {
  position: relative;
  background: #b2caef !important;
  background-size: contain;
  max-width: 96rem !important;
  right: 10rem !important;
}

.section--left {
  left: -132px !important;
  right: calc((100vw - 96rem) / 2 * -1);
}

.what--text {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 600;
  color: #062d97;
}

.what--subText {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #062d97;
}

.what--retirement {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  color: #f08600;
}

.what--2ndText {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  color: #062d97;
}

.title--font {
  font-size: 50px;
  font-family: "Montserrat" !important;
  font-weight: 700;
}

.title--span {
  background: linear-gradient(
    90deg,
    rgba(25, 176, 230, 1) 13%,
    rgba(47, 127, 215, 1) 34%,
    rgba(25, 175, 229, 1) 62%,
    rgba(69, 77, 201, 1) 86%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

}

.title--cardFont {
  font-size: 30px;
  font-family: "Poppins" !important;
  font-weight: 700;
}

.title--card {
  color: rgba(0, 32, 96);
}

.title--cardText {
  font-size: 15px;
  font-family: "Poppins" !important;
  color: rgba(0, 32, 96);
}

.what--nav .ant-anchor-link {
  padding: 7px 0 7px 3px;
}

.what--main .ant-anchor-wrapper {
  padding: 1em;
  border-radius: 20px;
  background: rgb(53, 181, 225) im !important;
  background: linear-gradient(
    90deg,
    rgba(53, 181, 225, 1) 0%,
    rgba(34, 144, 215, 1) 54%,
    rgba(2, 83, 199, 1) 100%
  ) !important;
}

.what--main .ant-anchor-ink::before {
  display: none !important;
}

.what--link .what--titleLink {
  font-family: "Poppins";
  font-weight: 700;
  align-items: center;
  width: 100%;
  font-size: 18px;
  text-transform: uppercase;
}

.what--main .what--icon {
  cursor: pointer;
  text-align: end !important;
  font-size: 1.5rem;
  margin-bottom: 0;
  /* color: #fff; */
}

.what--main .ant-anchor-ink-ball {
  display: none !important;
}

.what--nav .ant-anchor-link-title {
  color: #fff !important;
}

.what--nav .ant-anchor-link-active > .ant-anchor-link-title-active {
  color: #ff8e00 !important;
  background: #fff;
  border-radius: 5px;
  padding: 0.2rem;
}

.mutual-hover:hover p,
.wealth-hover:hover p,
.invest-hover:hover p,
.retire-hover:hover p,
.educ-hover:hover p,
.financial-hover:hover p {
  color: #ff8e00 !important;
}

@media (min-width: 992px) {
  .mt--70 {
    margin-top: -70px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .bg-light-what {
    left: 3rem !important;
  }

  .bg-dark-what {
    right: 3rem !important;
  }

  .what-we-do img {
    width: 100% !important;
  }

  .what--text {
    font-size: 24px !important;
  }

  .what--subText {
    font-size: 14px !important;
  }
}

@media (max-width: 740px) {
  /* .d-grid {
    display: grid !important;
  } */

  .services--card {
    margin-bottom: 3rem !important;
  }

  .title--font {
    font-size: 36px !important;
  }

  .px--sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .what--2ndText {
    font-size: 16px !important;
  }

  .mt--70 {
    margin-top: -50px !important;
  }

  .bg-light-what {
    left: 0 !important;
  }

  .d-grid .img-services {
    order: -1 !important;
    justify-content: center !important;
    text-align: center !important;
    margin-bottom: 1.5rem !important;
  }

  .d-dark .img-services {
    justify-content: center !important;
    text-align: center !important;
    margin-bottom: 1.5rem !important;
  }

  .img-services img {
    width: 75% !important;
  }

  .bg-dark-what {
    right: 0 !important;
  }

  .what--text {
    font-size: 20px !important;
  }

  .what--retirement {
    font-size: 18px !important;
  }

  .sm-width {
    width: 100% !important;
  }

  .sm--padding {
    padding: 1rem !important;
  }
}
