.ada-table th {
  background-color: #e3eef8 !important;
  font-size: 16px;
  font-weight: 600 !important;
  color: #062d97 !important;
}

.ada--title {
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 700;
}

.ada--span {
  color: #062d97;
}

.ada-bills {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  color: #062d97;
}

.ul-ada {
  padding-left: 1.5rem;
}

.ada--details {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: #062d97;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .adaForm1-tablet {
    white-space: nowrap !important;
  }

  .adaForm-bot {
    margin-bottom: 2rem !important;
  }

  .ada-ul-tablet {
    margin-bottom: 0.7rem !important;
  }
}

@media (max-width: 740px) {
  .ada--title {
    font-size: 24px !important;
    margin-top: 1rem !important;
  }

  .ul-ada {
    padding-left: 1rem !important;
  }

  .textAlign--sm {
    text-align: left !important;
    margin-bottom: 0.5rem !important;
  }

  .adaForm-top {
    margin-top: 1.5rem !important;
  }
}
