.howto--bg {
  background: #f4fcff !important;
}

.step--bg {
  background: rgba(91, 154, 213, 0.28) !important;
}

.border-bot {
  border-bottom: 0.0625rem solid #e9ecef !important;
}

.contentCard--text {
  font-family: "Poppins";
  font-size: 13px;
  /* color: #062d97; */
  color: #000;
  font-weight: 400;
}

.invest--font {
  font-size: 30px;
  font-family: "Poppins" !important;
  font-weight: 700;
}

.invest--title {
  color: #062d97 !important;
}

.manual--number {
  font-size: 20px;
  font-family: "Poppins" !important;
  background-color: #0076e4;
  width: 10%;
  text-align: center;
  border-radius: 50%;
  color: #fff;
}

.manual--text {
  font-family: "Poppins" !important;
  font-size: 22px;
  font-weight: 500;
  color: #062d97;
}

.subManual--text {
  font-family: "Poppins" !important;
  font-size: 20px;
  font-weight: 500;
  color: #062d97;
}

.lets--start {
  font-size: 16px;
  color: #062d97;
  bottom: 0 !important;
}

.howto--account td {
  padding: 0.5rem !important;
}

.instruction--title {
  font-size: 14px;
  color: #ff8e00;
  bottom: 0 !important;
}

.instructionTitle {
  font-size: 16px;
  bottom: 0 !important;
}

.subInvest {
  font-size: 20px;
}

.cardTitle--text {
  font-size: 14px;
  color: #ff8e00;
  bottom: 0 !important;
}

.instruction--text {
  font-size: 13px;
  color: #062d97;
  bottom: 0 !important;
}

.how--text {
  font-size: 14px;
  color: #000;
  bottom: 0 !important;
}

.instruction--content {
  font-size: 13px;
  color: #062d97;
  bottom: 0 !important;
  font-weight: 400;
}

.table--title {
  font-size: 16px;
  color: rgba(0, 32, 96);
  bottom: 0 !important;
  font-weight: 500;
}

.table--head td {
  padding: 0.6rem;
}

.table--head th {
  vertical-align: middle !important;
}

.table--content {
  font-size: 14px;
  color: rgba(0, 32, 96);
}

.table--head {
  font-size: 14px;
  color: rgba(0, 32, 96);
}

.table--size {
  width: 280px;
}

.thNew--size {
  width: 200px;
}

.thCurrency--size {
  width: 150px;
}

.bottom--text {
  bottom: 0 !important;
}

.step--text {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 18px;
  color: #ff8e00;
}

.step--colorText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  color: #062d97;
}

.sub--step {
  font-size: 16px;
  color: #147dde;
}

.click--dl button {
  font-size: 14px;
  font-weight: normal;
  text-transform: unset;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.sm-manual {
  width: 200px !important;
}

.mobile-view {
  display: none;
}

.step-invest-left {
  margin-left: 3.9rem !important;
}

/* New CSS */
.step-1 {
  margin-left: 10px;
}

.step-3 {
  margin-left: 20px;
}

@media (max-width: 576px) {
  .sm-padding {
    padding: 0 !important;
  }

  .column--padding {
    padding: 2rem !important;
  }

  .sm-manual {
    width: 300px !important;
  }
}

.panel--title-desc {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: #062d97;
}

.panel--desc {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: #062d97;
}

.redeemSM--show {
  display: none !important;
}

.redeem--process-left {
  margin-left: 3.5rem;
}

.fs--sm-title {
  display: none;
}

.online--video-text {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #062d97;
}

.panel-mobile-text {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: #062d97;
  text-align: left;
}

.panel-fundName {
  font-family: "Poppins";
  font-size: 12px;
  color: #062d97;
  font-weight: 500;
}

.panel-amount {
  font-family: "Poppins";
  font-size: 13px;
  color: #062d97;
  font-weight: 500;
}

.panel-currency {
  font-family: "Poppins";
  font-size: 12px;
  color: #8f8f8f;
  font-weight: 500;
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .ul-invest {
    padding-left: 1rem !important;
  }

  .topUp-tablet {
    padding-bottom: 1.3rem !important;
  }

  .invest-tablet,
  .redeem-tablet,
  .fs-tablet {
    padding: 1rem !important;
  }

  .mir-tablet {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .step-tablet {
    white-space: nowrap !important;
  }

  .linkForm-tablet {
    padding-left: 1.5rem !important;
    padding-bottom: 1.1rem !important;
  }
}

@media (max-width: 740px) {
  .howto--bg {
    background: #fff !important;
  }

  .reset-how-pad {
    padding: 1rem !important;
  }

  .step-invest-left {
    margin-left: 0 !important;
  }

  .redeemSM--show {
    display: flex !important;
  }

  .redeemStep-sm {
    margin-right: 1rem !important;
  }

  .click--dl button {
    font-size: 12px !important;
  }

  .formBtn-sm {
    margin-left: 3.6rem !important;
  }

  .redeemlg--show {
    display: none !important;
  }

  .reset-left {
    margin-left: 0 !important;
  }

  .ul-padding {
    padding-left: 2rem !important;
  }

  .step-sm-bottom {
    padding-bottom: 1rem !important;
  }

  .sm-left-step {
    margin-left: 1rem !important;
  }

  .reset-desk {
    border-top: 0rem solid #e9ecef !important;
    border-right: 0rem solid #e9ecef !important;
    border-left: 0rem solid #e9ecef !important;
    border-bottom: 0rem solid #e9ecef !important;
  }

  .padding-add {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .howto-smLeft {
    padding-left: 0 !important;
  }

  .padding-reset {
    padding-left: 0.5rem !important;
  }

  .width-sm {
    width: 100% !important;
  }

  .btn-dl-sm {
    margin-left: 2.5rem !important;
  }

  .invest-sm-top,
  .redeem-sm-top,
  .fs-sm-top {
    margin-top: 1rem !important;
  }

  .bottom-column-invest {
    margin-bottom: 2rem !important;
  }

  .ins-sm-bottom {
    margin-bottom: 0.4rem !important;
  }

  .ul-invest {
    padding-left: 1rem !important;
  }

  .invest--padding {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .redeem-padding,
  .fs-padding {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .instruction-sm {
    text-align: left !important;
  }

  /* Panel */
  .mobile-view .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    /* background: #002060 !important; */
    background: #e3eef8 !important;
    color: #062d97 !important;
    font-size: 14px;
    /*white-space: nowrap !important;*/
  }

  .mobile-view {
    display: block !important;
  }

  .large-view {
    display: none !important;
  }

  .howto--title {
    width: 100% !important;
  }

  .online--padding {
    padding: 1.5rem !important;
  }

  .lets--start {
    font-size: 14px !important;
  }

  .fs--sm-title {
    display: block !important;
  }

  .fs--lg-title {
    display: none !important;
  }

  .header-howto {
    display: none !important;
  }

  /* New panel */
  .panel--funds .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: 30px !important;
  }

  .panelNew-card .ant-card-body {
    padding: 15px !important;
  }

  .d-none-panel {
    display: none !important;
  }

  .d-block-row {
    display: table-row !important;
  }

  .panelNew-card .ant-table {
    border: 1px solid #e9ecef !important;
  }

  .panelNew-card .ant-table-thead > tr > th {
    padding: 8px 10px !important;
    text-align: center !important;
    font-family: "Poppins" !important;
  }

  .panelNew-card .ant-table-tbody > tr > td {
    padding: 8px 10px !important;
    font-family: "Poppins" !important;
    font-size: 12px !important;
    border: 0px solid #fff !important;
  }

  .invest--font {
    font-size: 24px !important;
  }
}
