.affinity-bg {
  /* background: url("~assets/img/affinity/banner-1.png") !important; */
  background: rgba(6, 45, 151, 0.36) !important;
  background-size: contain !important;
}

h1 {
  line-height: 1.2;
}

.announcement_float {
  width: 280px;
  position: fixed;
  z-index: 9999;
  background-color: #007ae2;
  bottom: 10px;
  right: 20px;
  padding: 10px;
  border-radius: 5px;
}

.carousel--mask {
  background: #062d975c !important;
  height: 100% !important;
  background-repeat: no-repeat !important;
  background-position: bottom center !important;
  background-size: cover !important;
  padding-bottom: 35rem !important;
}

.bg-video {
  position: relative;
  object-fit: contain;
  z-index: -1;
  width: 100%;
  height: auto;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.slide-1 {
  /* background-image: url("../../../img/affinity/home1-new.webp") !important; */
  width: 100% !important;
  height: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  background-size: cover !important;
  object-fit: contain !important;
}

.slide-2 {
  /* background-image: url("../../../img/affinity/home2.webp") !important; */
  /* background-image: url("../../../img/affinity/homegirl-new-dec.webp") !important; */
  height: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  background-size: cover !important;
  object-fit: contain !important;
}

.slide-3 {
  /* background-image: url("../../../img/affinity/home3-new.webp") !important; */
  /* background-image: url("../../../img/affinity/home3-new-dec.webp") !important; */
  height: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  background-size: cover !important;
  object-fit: contain !important;
}

.slide-4 {
  position: relative !important;
  /* background-image: url("../../../img/affinity/homeNew-3.webp") !important; */
  /* background-image: url("../../../img/affinity/home4-new-dec.webp") !important; */
  height: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  background-size: cover !important;
  object-fit: contain !important;
}

/* Banner our funds */
.bg-ourFunds {
  background: url("../../../img/affinity/ourfunds-new.webp");
  background-repeat: no-repeat !important;
  /* background-position: center center; */
  background-size: contain !important;
  overflow: auto !important;
}

/* .slide-5 {
  position: relative !important;
  background-image: url("../../../img/affinity/home-agma.webp") !important;
  height: 100% !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  background-size: cover !important;
  object-fit: contain !important;
} */

.padding-fluid {
  padding-left: 5rem;
  padding-right: 5rem;
}

.big-left {
  margin-left: 7.5rem;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.z-reset {
  z-index: 0 !important;
}

.z-show {
  cursor: pointer;
  z-index: 1 !important;
}

.carousel--title {
  font-family: "Montserrat" !important;
  font-size: 60px !important;
  font-weight: 700 !important;
  line-height: 1em !important;
}

.carousel--2ndSlide {
  font-family: "Merienda" !important;
  font-size: 50px !important;
  font-weight: 600 !important;
  line-height: 1em !important;
  font-style: italic !important;
  z-index: 1 !important;
}

.carousel--wealth {
  font-family: "Montserrat" !important;
  font-size: 60px !important;
  font-weight: 700 !important;
  line-height: 1em !important;
}

.carousel--future {
  font-family: "Montserrat" !important;
  font-size: 60px !important;
  font-weight: 700 !important;
  line-height: 1em !important;
}

.carousel--future1 {
  font-family: "Montserrat" !important;
  font-size: 45px !important;
  font-weight: 700 !important;
  line-height: 1em !important;
}

.carousel--subTitle {
  font-family: "Montserrat" !important;
  font-size: 40px !important;
  font-weight: 500 !important;
  line-height: 1em !important;
}

.carousel--subAmount {
  font-family: "Montserrat" !important;
  font-size: 50px !important;
  font-weight: 600 !important;
  line-height: 1em !important;
}

.carousel--subText {
  font-family: "Montserrat" !important;
  font-size: 30px !important;
  line-height: 1em !important;
}

.carousel2--subText {
  font-family: "Merienda" !important;
  font-size: 30px !important;
  line-height: 1em !important;
}

.carousel--subAffinity {
  font-family: "Montserrat" !important;
  font-size: 30px !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
}

.carousel--text {
  font-family: "Montserrat" !important;
  font-size: 26px !important;
  font-weight: 600 !important;
  line-height: 1em !important;
}

.carousel--futureSub {
  font-family: "Montserrat" !important;
  font-size: 26px !important;
  font-weight: 500 !important;
  line-height: 1em !important;
}

.carousel--futureSub2 {
  font-family: "Montserrat" !important;
  font-size: 26px !important;
  font-weight: 500 !important;
  line-height: 1em !important;
  opacity: 0;
}

.carouselText--mask {
  background: rgba(255, 255, 255, 0.397) !important;
  width: 50%;
  padding: 1rem;
}

.cardInsight--text {
  font-family: "Poppins" !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1em;
}

.cardInsight--subText {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-size: 14px !important;
}

.opacity-0 {
  opacity: 0;
}

.banner--title {
  font-family: "Montserrat" !important;
  font-size: 70px !important;
  text-align: left !important;
  font-weight: 700 !important;
  line-height: 1em !important;
}

.banner--titleNew {
  font-family: "Montserrat" !important;
  text-align: left !important;
  font-weight: 700 !important;
  line-height: 1.2em !important;
}

.border-bottom {
  border-bottom: 5px solid #fff !important;
  padding-top: 5px !important;
}

.border-bottom-mgmt {
  border-bottom: 2px solid rgb(87, 87, 87) !important;
  margin-bottom: 2px !important;
}

.banner--title-span {
  background: linear-gradient(
    to right,
    #19b0e6 24%,
    #2f7fd7 45%,
    #19afe5 50%,
    #454dc9 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.banner--subtitle {
  font-family: "Poppins" !important;
  text-align: left;
  font-size: 17px;
  color: #212529;
}

.banner--subWhite {
  font-family: "Poppins" !important;
  text-align: left;
  font-size: 17px;
  color: #fff;
}

.adjust-top {
  margin-top: 21rem !important;
}

.section--font {
  font-size: 40px;
  font-family: "Montserrat";
  font-weight: 700;
}

.listFund--font {
  font-size: 38px !important;
  font-family: "Montserrat" !important;
  text-align: center !important;
}

.section--title {
  font-size: 2.3rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #03254c;
}

.section2--title {
  background: rgb(254, 177, 31);
  background: linear-gradient(
    90deg,
    rgba(254, 177, 31, 1) 21%,
    rgba(249, 139, 36, 1) 36%,
    rgba(247, 126, 37, 1) 50%,
    rgba(251, 92, 64, 1) 76%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.section--text {
  font-family: "Poppins" !important;
  text-align: left;
  font-size: 16px;
  color: #062d97;
}

.build--text {
  font-family: "Poppins" !important;
  text-align: center;
  font-size: 17px;
  color: #0c71c3;
}

.grow-text {
  font-family: "Poppins" !important;
  text-align: left;
  font-size: 17px;
  color: #212529;
}

.column--bg {
  /* background-color: #f4fcff !important; */
  background: rgb(224, 233, 243);
  background: linear-gradient(
    180deg,
    rgba(224, 233, 243, 1) 40%,
    rgba(220, 230, 241, 0.9668242296918768) 74%,
    rgba(249, 250, 252, 0.9164040616246498) 100%
  );
}

.text-primary-color {
  color: #062d97 !important;
}

.column--img {
  background: url("~assets/img/affinity/column-bg.webp") 50% center !important;
  background-repeat: no-repeat !important;
  /* background-position: 50% fixed !important; */
  background-size: cover !important;
  z-index: 1 !important;
  overflow: visible;
}

.services--font {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #062d97;
  transition: 0.3s;
}

.carousel-bg {
  background: rgb(40, 126, 229);
  background: linear-gradient(
    90deg,
    rgba(40, 126, 229, 1) 7%,
    rgba(43, 144, 232, 1) 56%,
    rgba(48, 176, 241, 1) 88%
  );
}

.ourFunds--text {
  font-size: 16px;
  color: #000;
}

.slide2-left {
  margin-left: 3rem;
}

/* Insight Card */
.insightCard::after {
  border-bottom: 15px solid transparent !important;
  border-top: 15px solid transparent !important;
  border-right: 15px solid #fff !important;
  content: "" !important;
  height: 0 !important;
  position: absolute !important;
  right: 30px !important;
  top: 25px !important;
  width: 0 !important;
}

.insightCard--right::after {
  border-bottom: 15px solid transparent !important;
  border-top: 15px solid transparent !important;
  border-left: 15px solid #fff !important;
  border-right: none;
  content: "" !important;
  height: 0 !important;
  position: absolute !important;
  right: 397px !important;
  top: 25px !important;
  width: 0 !important;
}

.main--card {
  opacity: 0;
}

/* .insight-bg {
  background: url("../../../img/affinity/notes-manager.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
} */

/* .key-news-bg {
  background: url("../../../img/affinity/key-news.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
} */

.insight-text {
  font-family: "Poppins" !important;
  color: #1c7dd6 !important;
  font-weight: 700 !important;
}

.notes-border {
  border-bottom: 1px solid #0076e4;
}

.btn-insight {
  color: #fff;
  background-color: #ff8e00;
  border-color: #ff8e00;
}

.btn-insight:hover {
  color: #fff;
  background-color: #da8010;
  border-color: #da8010;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 155px;
  border: none;
}

.insightCard--title {
  font-size: 15px;
  font-weight: 500;
  color: rgb(254, 177, 31);
}

.insightCard--sub {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  color: #062d97;
}

.insightCard--sub2 {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  color: #6e6e6e;
}

.news--top {
  margin-top: 2.5rem;
}

.w-90 {
  width: 90% !important;
}

.w-60 {
  width: 60% !important;
}

.btn-width {
  padding-left: 7rem;
  padding-right: 7rem;
}

.btn-width2 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.btn-primary-brand {
  font-family: "Poppins" !important;
  color: #fff;
  background-color: #0076e4;
  border-color: #0076e4;
}

.btn-primary-brand:hover {
  color: #fff;
  background-color: #0054a3;
  border-color: #0054a3;
}

.btn-secondary-brand {
  font-family: "Poppins" !important;
  color: #fff;
  background-color: #ff8e00;
  border-color: #ff8e00;
}

.btn-secondary-brand:hover {
  color: #fff;
  background-color: #db7900;
  border: #db7900;
}

.btn-started {
  color: #0076e4;
  background-color: #fff;
  border-color: #0076e4;
}

.btn-started:hover {
  color: #fff;
  background-color: #0076e4;
  border-color: #0076e4;
}

.nav--funds .nav-link {
  color: #0076e4 !important;
  font-size: 18px;
  font-family: "Poppins" !important;
}

.nav--funds .nav-link.active {
  color: #fff !important;
  background-color: #0076e4 !important;
}

.solution--fund {
  font-family: "Poppins" !important;
}

.btn-grow {
  font-family: "Montserrat" !important;
  font-weight: normal !important;
  font-size: 20px !important;
  color: #ff8e00 !important;
  background: #fff !important;
  border-color: #ff8e00 !important;
  border-radius: 3px !important;
  border-width: 2px !important;
}

.btn-grow:hover {
  color: #fff !important;
  background: #ff8e00 !important;
}

/* Table */
.table--listFund {
  font-family: "Poppins" !important;
  color: #212529 !important;
}

.table--listFund .thead-light th {
  font-family: "Poppins" !important;
  color: #212529 !important;
  background-color: #edf2f600 !important;
}

.table--listFund.table-hover tbody tr:hover {
  color: #212529 !important;
  background-color: #eff7ff !important;
}

.carousel--fund {
  font-family: "Poppins" !important;
}

.slide2--mb {
  display: none !important;
}

/* New insights */
.newInsight--title {
  font-family: "Poppins";
  font-size: 15px;
  color: #062d97;
  font-weight: 600;
  margin-top: 10px;
}

.newInsight--text {
  font-family: "Poppins";
  font-size: 14px;
  color: #062d97;
  font-weight: 400;
}

.insight-hover:hover {
  transform: scale(1.05);
}

.insights-body .card-footer {
  padding: 0.7rem 0.7rem !important;
}

/* Macbook Adjustment */
.slide1--down {
  margin-bottom: 6rem;
}

.slide3--up {
  margin-top: 3rem;
}

.slide4-width {
  width: 75%;
}

.ourFundsTop--lg {
  margin-top: 0rem;
}

.news-slick .slick-slide {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  transition: all;
}
.news-slick .slick-current {
  transform: scale(1.2, 1.2);
  opacity: 1;
}

.insight--date {
  font-family: "Poppins";
  font-size: 10px;
  color: #686767;
  font-weight: 400;
}

.news-slick .slick-list {
  background-color: #fff !important;
}

/* banner custom paging */
.banner-paging-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: -145px;
}

/* .banner-paging-wrapper .slick-track {
  width: 1308px !important;
} */

.banner-paging-wrapper .slick-slide {
  width: 327px !important;
  padding-left: 70px;
}

.banner-paging-container {
  width: 2px !important;
  min-height: 110px !important;
  border-left: 1px solid #ee8e18;
  transition: all 0.1s !important;
}

.banner-paging {
  font-family: "Poppins";
  font-size: 14px;
  color: #fff;
  letter-spacing: 1px;
  line-height: 14px;
  text-decoration: none !important;
}

.banner-content-sub {
  font-family: "Poppins";
  font-size: 12px;
  color: #fff;
  margin: 10px 0 !important;
  font-weight: 400 !important;
  letter-spacing: 0.24px;
  line-height: 12px;
  display: inline-block;
  padding-left: 20px;
  width: 220px;
}

.banner-content-title {
  font-family: "Poppins";
  font-size: 14px;
  color: #fff;
  margin: 5px 0 !important;
  letter-spacing: -0.1px;
  font-weight: 600 !important;
  line-height: 19px;
  display: inline-block;
  padding-left: 20px;
  width: 220px;
}

.banner-paging-explore {
  width: auto !important;
  border-bottom: 5px solid #ee8e18;
  padding: 0 !important;
  margin: 35px !important;
  position: relative;
}

.banner-paging-explore-link {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 1px;
  line-height: 14px;
  text-decoration: none;
}

.banner-paging-container:hover {
  box-shadow: inset 10px 0 0 0 #ee8e18;
  background-color: #062d97;
  width: 239px !important;
  transition: all 1s !important;
  cursor: pointer;
  text-decoration: underline;
}

.new-section-hero-top {
  padding-top: 6.2rem !important;
}

.banner-display-sm {
  display: none !important;
}

.banner-display-lg {
  display: block;
}

.talkUs-img {
  width: 400px;
}

.talkUs-img2 {
  width: 300px;
}

.body--title-talk {
  font-family: "Montserrat";
  font-size: 42px;
  color: #1870d8;
  font-weight: 700 !important;
  margin-top: 10px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  letter-spacing: -1px;
}

.body--title-new {
  font-family: "Poppins";
  font-size: 22px;
  color: #062d97;
  font-weight: 600;
  margin-top: 1rem;
}

.body--text-talk {
  font-family: "Poppins";
  font-size: 16px;
  color: #062d97;
  font-weight: 400;
}

.talkUs-wrapper .card-body {
  padding: 0.8rem !important;
  cursor: pointer;
}

.body--title {
  font-family: "Poppins";
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.body--text {
  font-family: "Poppins";
  font-size: 13px;
  color: #000;
  font-weight: 400;
}

.body--text-sub {
  font-family: "Poppins";
  font-size: 10px;
  color: #000;
  font-weight: 400;
  font-style: italic;
}

.body--italic {
  font-family: "Poppins";
  font-size: 11px;
  color: #8f8f8f;
  font-weight: 400;
  font-style: italic;
}

.rsp-number {
  font-family: "Poppins";
  font-size: 18px;
  color: #ff8e00;
  font-weight: 600;
  padding-right: 10px;
}

.hr-blue {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 0.0625rem solid #0076e4 !important;
}

.margin--rsp {
  margin-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.margin--rsp-text {
  margin-top: 3rem;
  padding-left: 4rem;
  padding-right: 4rem;
}

.px--mgmt {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px--adviser {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px--invest {
  padding-left: 4rem;
  padding-right: 4rem;
}

.lottie--sm {
  margin-top: 3rem;
}

.lottie--rsp {
  margin-top: 3rem;
}

.lottie--rsp-2 {
  margin-top: 5rem;
}

.card--mgmt {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.bpi-rsp-img {
  max-width: 70%;
}

.rsp--img {
  margin-top: 3rem;
}

.border--rsp {
  border: 5px solid #0076e4;
}

.padding-sm-new {
  padding-bottom: 1rem;
}

.events-modal {
  font-family: "Poppins";
  font-size: 14px;
  color: #03254c;
  font-weight: 500;
}

.event-card-size {
  width: 350px !important;
}

.marquee-event .marquee {
  min-width: 96% !important;
}

.news-spacing {
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.modal-events .modal-title {
  font-size: 18px !important;
}

.talk-sm-none {
  display: none;
}

.talkUs-top {
  margin-top: 3rem;
}

/* New our funds */
.icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.icon-wrapper img {
  width: 22%;
  padding-right: 35px;
}

.icon-fund {
  padding-right: 45px;
}

.newFund-title {
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 600;
}

.newFund-sub {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
}

.newFund-color {
  background: linear-gradient(
    90deg,
    rgba(25, 176, 230, 1) 13%,
    rgba(47, 127, 215, 1) 34%,
    rgba(25, 175, 229, 1) 62%,
    rgba(69, 77, 201, 1) 86%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.category-title {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #062d97;
  text-align: left;
}

.category-text {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: #8f8f8f;
  text-align: left;
}

.gallery-funds {
  transform: translateY(0px);
  transition-duration: 500ms;
  opacity: 1 !important;
}

.funds-image {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.funds-image img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: "object-fit: cover";
  object-fit: cover;
  border-radius: 5px;
  -webkit-transition: -webkit-transform 150ms linear;
  transition: -webkit-transform 150ms linear;
  transition: transform 150ms linear;
  transition: transform 150ms linear, -webkit-transform 150ms linear;
  will-change: transform;
}

.funds-image:hover img {
  transform: scale(1.05);
}

.fund-wrapper .card-body {
  padding: 0.7rem !important;
}

.category-card {
  cursor: pointer;
  transition: 150ms linear;
  border: 0 solid #fff !important;
  background-color: transparent !important;
}

.category-card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.newFund-padding {
  padding-left: 6rem;
  padding-right: 6rem;
}

.our-funds-top {
  margin-top: 3rem;
}

/* Banner */
.banner-home .ant-carousel .slick-prev {
  left: 15px !important;
  color: #fff !important;
  width: 60px;
  height: 60px;
  z-index: 1000 !important;
}

.banner-home .ant-carousel .slick-next {
  right: 15px !important;
  color: #fff !important;
  font-size: 16px !important;
  width: 60px;
  height: 60px;
  z-index: 1000 !important;
}

.height-banner {
  /* height: 700px; */
  height: 640px;
}

.ourFunds-sm {
  display: none;
}

.category-card-top {
  margin-top: 1rem;
}

.mb-custom {
  margin-bottom: 6rem;
}

.mt-custom {
  margin-top: 3rem;
}

.invest-adviser {
  display: flex;
  border-radius: 10px;
  margin-top: 5rem;
}

.bg-adviser {
  background: url("../../../img/banner/adviser.png");
  background-repeat: no-repeat !important;
  background-position: center center;
  background-size: contain !important;
  overflow: auto !important;
}

@media screen and (max-width: 600px) {
  .height-banner {
    height: auto !important;
  }

  .affinity-bg {
    padding-bottom: 0 !important;
  }

  .newFund-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .icon-fund {
    padding-right: 0 !important;
  }

  .funds-animate {
    margin-top: 6rem !important;
  }

  .fund-wrapper {
    margin-top: 2rem !important;
  }

  .icon-wrapper img {
    width: 15% !important;
    padding-right: 20px !important;
  }

  .banner--titleNew {
    font-size: 17px;
  }

  .adjust-top {
    margin-top: 4rem !important;
  }

  .bg-video {
    width: 118%;
    bottom: 0 !important;
  }

  .border-bottom {
    border-bottom: 2px solid #fff !important;
  }

  .sm-banner2-center {
    justify-content: start !important;
  }

  .img--banner2-sm {
    width: 40% !important;
    height: 40% !important;
  }

  .news-slick .slick-slide {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

/* Tablet */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .height-banner {
    height: auto !important;
  }

  .carousel--subTitle {
    font-size: 30px !important;
  }

  .slideText-tablet {
    width: 100% !important;
  }

  .slide-1 {
    /* background-image: url("../../../img/affinity/new-slide1.webp") !important; */
    height: 100% !important;
    position: relative !important;
    background-size: cover !important;
    background-position: 90% 75% !important;
  }

  .padding-fluid {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .home--main {
    margin-top: 8rem !important;
  }

  .d-tb-flex {
    display: block !important;
  }

  .slide2--desk {
    display: none !important;
  }

  .slide2--mb {
    display: block !important;
  }

  .slide2-mb {
    margin-bottom: 5.7rem !important;
  }

  .top-tb {
    margin-top: 3rem !important;
  }

  .tablet--max {
    flex: 0 0 70.66667% !;
    max-width: 70.66667% !important;
  }

  .slide3-btn {
    margin-bottom: 9.8rem !important;
  }

  .wealth-top {
    margin-bottom: 3.5rem !important;
  }

  .section--font {
    font-size: 36px !important;
  }

  .desktop--insights {
    display: none !important;
  }

  .mobile--insights {
    display: block !important;
  }

  .insights--img {
    width: 100px !important;
    height: 100px !important;
    object-fit: cover !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .insights--content {
    margin-left: 10px !important;
    padding-right: 0 !important;
  }

  .btn-transform {
    font-size: 14px !important;
  }

  .type-tablet {
    margin-top: 1rem !important;
    margin-bottom: -0.2rem !important;
  }

  .time-tablet {
    margin-top: 3rem !important;
  }

  .currency-tablet {
    margin-top: 3rem !important;
  }

  .fundText-details {
    font-size: 14px !important;
  }

  /* navbar */
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    background: #0076e4 !important;
  }

  .navbar-collapse {
    width: calc(100% - 0rem) !important;
    /* height: 760px !important; */
    margin: 0 !important;
    border-radius: 0 !important;
  }

  .navbar-collapse .navbar-toggler span {
    background: #fff !important;
  }

  .new-section-hero-top {
    padding-top: 9.5rem !important;
  }

  .banner-paging-wrapper {
    justify-content: unset !important;
    display: unset !important;
  }

  .banner-paging-wrapper {
    top: 30px !important;
    bottom: 0 !important;
  }

  .banner-paging-wrapper .slick-slide {
    width: 277px !important;
  }

  .sm--banner-bg {
    background: rgb(224, 233, 243);
    background: linear-gradient(
      180deg,
      rgba(224, 233, 243, 1) 40%,
      rgba(220, 230, 241, 0.9668242296918768) 74%,
      rgba(249, 250, 252, 0.9164040616246498) 100%
    );
    padding-bottom: 4rem !important;
  }

  .banner-paging,
  .banner-content-sub,
  .banner-content-title,
  .banner-paging-explore-link {
    color: #062d97 !important;
  }

  .banner-paging-wrapper .ant-carousel .slick-dots {
    bottom: -20px !important;
  }

  .home--main {
    margin-top: 4rem !important;
  }

  /* Banner our funds */
  .bg-ourFunds {
    background-position: 15% 75% !important;
  }
}

@media (min-width: 992px) {
  .navbar-brand {
    margin-right: 0 !important;
  }

  .navbar-collapse {
    white-space: nowrap !important;
  }

  .text--md {
    font-size: 18px !important;
  }
  .slideLeft--md {
    margin-left: 0 !important;
  }
}

@media (min-width: 1199.98px) {
  .navbar-brand {
    margin-right: 1rem !important;
  }

  .navbar-collapse {
    white-space: normal !important;
  }

  .navbar-nav .nav-link {
    padding: 1rem;
  }

  .text--md {
    font-size: 25px !important;
    white-space: nowrap !important;
  }
  .amount--md {
    white-space: nowrap !important;
  }

  .slideLeft--md {
    margin-left: 2rem !important;
  }

  .height-banner {
    height: 640px !important;
  }
}

@media (max-width: 740px) {
  .invest-adviser {
    margin-top: 3rem !important;
    margin-bottom: 1rem !important;
  }

  .bg-adviser {
    background: #fff !important;
    background-repeat: no-repeat !important;
    background-position: center center;
    background-size: contain !important;
    overflow: auto !important;
  }

  .body--title-new {
    padding-top: 2rem !important;
  }

  .ourFunds-desk {
    display: none !important;
  }

  .ourFunds-sm {
    display: block !important;
  }

  .height-banner {
    height: auto !important;
  }

  .bg-ourFunds {
    background: url("../../../img/affinity/ourfunds2.webp") !important;
    background-repeat: no-repeat !important;
    background-position: 79% 75% !important;
    background-size: cover !important;
  }

  .newFund--smTop {
    margin-top: 2.5rem !important;
    padding-bottom: 3rem !important;
  }

  .talkUs-img {
    width: 300px !important;
    margin-top: 2rem !important;
  }

  .talkUs-img2 {
    width: 180px !important;
    margin-top: 2rem !important;
    text-align: left !important;
  }

  .talk-sm-none {
    display: block !important;
  }

  .talk-lg-none {
    display: none !important;
  }

  .talkUs-top {
    margin-top: 1rem !important;
  }

  .body--text-talk {
    font-size: 13px !important;
  }

  .talkUs-lg-top {
    margin-top: 2rem;
  }

  .event-card-size {
    width: 280px !important;
  }

  .body--title-talk {
    font-size: 32px !important;
  }

  .kaMU-img {
    margin-left: 10px !important;
  }

  .news-spacing {
    margin-top: 1rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .new-section-hero-top {
    padding-top: 8.5rem !important;
  }

  .banner-paging-wrapper {
    top: 30px !important;
    bottom: 0 !important;
  }

  .banner-paging,
  .banner-content-sub,
  .banner-content-title,
  .banner-paging-explore-link {
    color: #062d97 !important;
  }

  .banner-content-title {
    font-weight: 500 !important;
  }

  /* .banner-paging-wrapper .slick-track {
    width: 4620px !important;
  }

  .banner-paging-wrapper .slick-slide {
    width: 294px !important;
    left: 0 !important;
  } */

  .banner-paging-wrapper .slick-slide {
    width: 360px !important;
  }

  .banner-paging-wrapper {
    justify-content: unset !important;
    display: unset !important;
  }

  .banner-paging-wrapper .ant-carousel .slick-dots {
    bottom: -20px !important;
  }

  .sm--banner-bg {
    background: rgb(224, 233, 243);
    background: linear-gradient(
      180deg,
      rgba(224, 233, 243, 1) 40%,
      rgba(220, 230, 241, 0.9668242296918768) 74%,
      rgba(249, 250, 252, 0.9164040616246498) 100%
    );
    padding-bottom: 4rem !important;
  }

  .sm--banner-funds {
    background: rgb(224, 233, 243);
    background: linear-gradient(
      180deg,
      rgba(224, 233, 243, 1) 2%,
      rgba(220, 230, 241, 0.9668242296918768) 1%,
      rgba(249, 250, 252, 0.9164040616246498) 7%
    );
    border: 0 solid rgb(224, 233, 243) !important;
  }

  .sm--fund-transparent {
    background: transparent !important;
  }

  .sm--banner-new {
    background: rgb(224, 233, 243) !important;
    border: 0 solid rgb(224, 233, 243) !important;
  }

  .services--font {
    font-size: 18px !important;
  }

  .whatReset--top {
    margin-top: 0 !important;
  }

  .slide2--mb {
    display: block !important;
  }

  .slide2--desk {
    display: none !important;
  }

  .line-sm {
    line-height: 0.8em !important;
  }

  .title--section-sm {
    font-size: 24px !important;
  }

  .our--title-sm {
    font-size: 24px !important;
  }

  .section--sm {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .ourFunds--text {
    font-size: 13px !important;
    font-weight: 400;
  }

  .ourCards-sm {
    margin-top: 1.5rem !important;
  }

  .section--font h1 {
    line-height: 1 !important;
  }

  .banner-sm {
    overflow: visible !important ;
  }

  .home--container {
    /* margin-top: 26rem !important; */
    margin-top: 0 !important;
  }

  .slide1-w {
    width: 100% !important;
  }

  .sm-bottom {
    margin-bottom: 0.5rem !important;
  }

  .top--reset {
    margin-top: 0 !important;
  }

  .slide1-bottomReset,
  .slide2-bottomReset,
  .slide3-bottomReset {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .bottom--reset {
    margin-bottom: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .pt--reset {
    padding-top: 0 !important;
  }

  .carousel--subAmount {
    font-size: 36px !important;
    margin-left: 0 !important;
  }

  .carouselText-sm {
    font-size: 20px !important;
  }

  .carousel-paddingTop {
    margin-top: 1rem !important;
  }

  .carouselPad--reset {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .carouselText-slide2 {
    margin-top: 45px;
    font-size: 18px !important;
    width: 100% !important;
    text-align: left !important;
    line-height: 1.3em !important;
  }

  .carouselText2-slide2 {
    margin-top: 45px;
    font-size: 18px !important;
    /* width: 100% !important; */
    text-align: left !important;
    line-height: 1.3em !important;
  }

  .title--sm {
    font-size: 36px !important;
  }

  .subText--sm {
    font-size: 18px !important;
  }

  .carousel--wealth {
    font-size: 30px !important;
  }

  .text--top {
    margin-top: 6rem !important;
  }

  .slide1--top {
    margin-top: -2rem !important;
    margin-bottom: 1rem !important;
  }

  .slide1-bottom {
    margin-bottom: 0.5rem !important;
  }

  .slide1-pad {
    text-align: left !important;
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .justify-sm {
    justify-content: start !important;
  }

  .top-reset {
    margin-top: 0 !important;
  }

  .wealth-top {
    margin-top: 2rem !important;
    padding-top: 8rem !important;
    padding-bottom: 0 !important;
  }

  .slide2-mb {
    margin-bottom: -5.7rem !important;
  }

  .md-mb {
    margin-bottom: 2rem !important;
  }

  .slide-1 {
    /* background-image: url("../../../img/affinity/new-slide1.webp") !important; */
    /* background-image: url("../../../img/affinity/banner1-sm-dec.webp") !important; */
    height: 100% !important;
    position: relative !important;
    background-size: cover !important;
    background-position: 90% 75% !important;
  }

  .slide-2 {
    /* background-image: url("../../../img/affinity/new-slide2.webp") !important; */
    /* background-image: url("../../../img/affinity/banner2-sm-dec.webp") !important; */
    height: 100% !important;
    position: relative !important;
    background-size: cover !important;
    background-position: 50% 75% !important;
  }

  .slide-3 {
    /* background-image: url("../../../img/affinity/new-slide3.webp") !important; */
    /* background-image: url("../../../img/affinity/banner3-sm-dec.webp") !important; */
    position: relative !important;
    background-size: cover !important;
    background-position: 52% 75% !important;
  }

  .slide-4 {
    /* background-image: url("../../../img/affinity/new-home-sm.webp") !important; */
    /* background-image: url("../../../img/affinity/banner4-sm-dec.webp") !important; */
    height: 100% !important;
    position: relative !important;
    background-size: cover !important;
    background-position: 30% 75% !important;
  }

  .padding-fluid {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .big-left {
    margin-left: 3rem !important;
  }

  .slide2-left {
    margin-left: 0.5rem !important;
  }

  .slide2--top {
    margin-top: 0 !important;
  }

  .global-reset {
    margin-left: 0 !important;
  }

  .sm-adjust {
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
  }

  .slide4Bottom-btn {
    margin-bottom: 5rem !important;
  }

  .slide4-smTop {
    margin-top: 6.5rem !important;
  }

  .slide3-btn {
    margin-top: 2rem !important;
    margin-bottom: 2.5rem !important;
  }

  .slide3-bottom {
    margin-bottom: 1rem !important;
  }

  .mt-start {
    margin-top: 0 !important;
  }

  .futureSub-sm {
    font-weight: 500 !important;
    font-size: 35px !important;
    margin-top: 6rem !important;
  }

  .carousel--futureSub {
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  .carousel--futureSub2 {
    font-size: 18px !important;
    font-weight: 600 !important;
    opacity: 1 !important;
  }

  .slide4-color {
    color: #fff !important;
  }

  /* Affinity Connotes */
  .affinity-sm-padd {
    padding: 1.5rem !important;
  }

  .insight-sm-padd {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .insight-sm-top {
    margin-top: 0 !important;
  }

  .align-sm {
    text-align: left !important;
  }

  /* insight */
  .insight--pad {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .insight--sm {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .daily-sm-top {
    margin-top: 1.5rem !important;
  }

  .slide3-hide {
    opacity: 0 !important;
  }

  .cardInsight--subText2 {
    font-size: 14px !important;
  }

  .cardInsight--text {
    font-size: 20px !important;
  }

  .carouselTextSM--slide2 {
    font-family: "Poppins" !important;
    font-size: 18px !important;
    font-weight: 400 !important;
  }

  .at--sm {
    font-size: 18px !important;
  }

  .ourFunds--sm-left {
    text-align: left !important;
  }

  .ourFundsTop--lg {
    margin-top: 0rem !important;
  }

  .news-slick .slick-slide {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .banner-display-sm {
    display: block !important;
  }

  .banner-display-lg {
    display: none !important;
  }

  .px--mgmt {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px--adviser {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px--invest {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .margin--rsp,
  .margin--rsp-text {
    margin-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lottie--sm div {
    width: 200px !important;
  }

  .lottie--rsp div {
    width: 190px !important;
  }

  .lottie--rsp-2 div {
    width: 160px !important;
  }

  .rsp--img {
    margin-top: 3rem !important;
  }

  .bpi-rsp-img {
    max-width: 80% !important;
  }

  .lottie--sm {
    margin-top: -1rem !important;
  }

  .lottie--rsp-2 {
    margin-top: -1rem !important;
  }

  .lottie--rsp {
    margin-top: -1rem !important;
  }

  .card--mgmt {
    margin-top: 1rem !important;
  }

  .price--mgmt-sm {
    margin-top: 20px !important;
  }

  .price--reset-px {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .price--reset-px .card-body {
    padding: 1rem !important;
  }

  .banner-paging-wrapper .slick-slide {
    padding-left: 20px !important;
  }

  .banner-content-sub,
  .banner-content-title {
    padding-left: 0px !important;
  }

  .banner-content-title {
    font-size: 14px !important;
  }

  .banner-content-sub {
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  .banner-paging-explore-link {
    font-size: 14px !important;
    text-align: center !important;
  }

  .banner-paging-explore {
    left: 50px !important;
  }

  .sm--gradient-funds {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .padding-sm-new {
    padding-bottom: 2rem !important;
  }

  .news-card-sm {
    width: 30% !important;
  }

  .marquee-event .marquee {
    min-width: 100% !important;
  }

  .modal-video-close-btn {
    width: 15px !important;
    height: 55px !important;
  }

  .category-title {
    font-size: 16px !important;
  }

  .category-text {
    font-size: 14px !important;
  }

  .category-card-sm .card {
    margin-bottom: 0 !important;
  }

  .category-card-top {
    margin-top: 0 !important;
  }

  .mb-custom {
    margin-bottom: 0 !important;
  }

  .mt-custom {
    margin-top: 0 !important;
  }

  .our-funds-top {
    margin-top: 1.5rem !important;
  }
}

@media (min-width: 1920px) {
  .height-banner {
    height: 900px !important;
  }

  .kaMU-img {
    margin-left: 10px !important;
  }

  .large-btn {
    margin-bottom: 6rem !important;
  }

  .home--main {
    margin-top: 13rem !important;
  }

  .slide1--top {
    margin-top: 8.5rem !important;
  }

  .slide2--top {
    margin-bottom: 12rem !important;
  }

  .slide3-btn {
    margin-bottom: 15rem !important;
  }

  .slide4-lgTop {
    margin-top: 7rem !important;
  }

  .slide4-btn {
    margin-bottom: 9rem !important;
  }

  .slide4-width {
    width: 50% !important;
  }

  .ourFundsTop--lg {
    /* margin-top: 14rem; */
    margin-top: 0rem !important;
    padding-bottom: 10rem !important;
  }

  .slide1--down {
    margin-bottom: 10rem !important;
  }

  .slide3--up {
    margin-top: 10rem !important;
  }
}

/* @media screen and (max-width: 1920px) {
  .ourFundsTop--lg {
    margin-top: 2rem !important;
    padding-bottom: 10rem !important;
  }
} */

@media screen and (min-device-width: 1199.98px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .slide1--top {
    margin-top: 8.5rem !important;
  }

  .slide2--top {
    margin-bottom: 12rem !important;
  }

  .slide4-lgTop {
    margin-top: 7rem !important;
  }

  .slide4-btn {
    margin-bottom: 9rem !important;
  }

  .slide4-width {
    width: 50% !important;
  }

  .ourFundsTop--lg {
    margin-top: 4rem !important;
    /* margin-top: -3rem !important; */
  }

  .banner-paging-wrapper .slick-slide {
    width: 290px !important;
  }

  .slide1--down {
    margin-bottom: 10rem !important;
  }

  .slide3--up {
    margin-top: 10rem !important;
  }

  .mac-adjust {
    margin-top: 2.4rem !important;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0 !important;
  }
}

/* for iphones */
@media only screen and (min-width: 428px) and (max-width: 428px) {
  .banner-paging-wrapper .slick-slide {
    width: 430px !important;
  }

  .banner-paging-explore {
    left: 80px !important;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .banner-paging-wrapper .slick-slide {
    width: 414px !important;
  }

  .banner-paging-explore {
    left: 80px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .ourFunds-sm {
    display: block !important;
  }

  .ourFunds-desk {
    display: none !important;
  }

  .fund-wrapper {
    margin-top: 2rem !important;
  }

  .newFund-padding {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .mobile--insights {
    max-width: 100% !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

@media screen and (max-width: 575px) {
  .announcement_float {
    width: 90%;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}
