/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merienda:wght@700&display=swap");

/* Custom CSS */
@import "pages/home/home.css";
@import "pages/about/about.css";
@import "pages/funds/funds.css";
@import "pages/funds/fundDetails.css";
@import "pages/forms/forms.css";
@import "pages/howto/howto.css";
@import "pages/contact/contact.css";
@import "pages/payment/payment.css";
@import "pages/investing/investing.css";
@import "pages/faq/faq.css";
@import "pages/faq/whatWeDo.css";
@import "pages/online/online.css";
@import "pages/home/nvestmo.css";
@import "pages/home/navpu.css";
@import "pages/contact/careers.css";
@import "pages/corporate/corporate.css";
@import "pages/home/insights.css";
@import "pages/online/ada.css";
@import "pages/calculator/calculator.css";
@import "pages/journey/journey.css";
@import "pages/journey/journeyKYC.css";

html,
body {
  font-family: "Poppins" !important;
  scroll-padding-top: 42px;
  scroll-behavior: smooth;
}

p,
ul,
dt,
dl {
  line-height: 1.5 !important;
}

/* bg */
.bg-primary-brand {
  background: #0076e4 !important;
}

.bg-dark-affinity {
  background: #062d97 !important;
}

.bg-affinity-gradient {
  background: rgb(224, 233, 243);
  background: linear-gradient(
    180deg,
    rgba(224, 233, 243, 1) 40%,
    rgba(220, 230, 241, 0.9668242296918768) 74%,
    rgba(249, 250, 252, 0.9164040616246498) 100%
  );
}
.accordion > .ant-collapse-item {
  border-bottom: none !important;
}
.accordion > .ant-collapse-item > .ant-collapse-header {
  padding: 0 !important;
  align-items: center !important;
}
.accordion
  > .ant-collapse-item
  > .ant-collapse-header
  > .ant-collapse-expand-icon {
  display: flex;
}
.accordion
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0;
  padding-top: 0;
  color: #062d97 !important;
}
.bg-affinity-img {
  background: url("~assets/img/affinity/column-bg.webp") !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
}
.financial-bg {
  background: url("~assets/img/affinity/financial-bg.webp") !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
}
.card-deck-portfolio {
  gap: 2rem;
  font-weight: 600;
  font-size: 14px;
}
.commulative-si-mobile {
  display: none;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
}
.bod-description {
  font-size: 0.875rem;
  padding-bottom: 2rem;
}
.learn-more-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  bottom: 0;
  position: absolute;
  width: calc(100% - 3rem);
}
.commulative-si-full {
  display: block;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
}
/* text */
.text-primary-color {
  color: #062d97 !important;
}

.text-brand-secondary {
  color: #ff8e00;
}

.text-affinity-blue {
  color: #0076e4 !important;
}

.text-orange-affinity {
  color: #fc760d !important;
}

.text-black-affinity {
  color: #000 !important;
}

.text-gray-secondary {
  color: #8f8f8f !important;
}

.text-footer-title {
  color: #94c3f8 !important;
}

.text-gray-new {
  color: #666666 !important;
}

.text-white {
  color: #fff !important;
}

.text-inherit {
  color: inherit;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-transform {
  font-size: 16px;
  text-transform: unset !important;
}

.bg-primary-blue:hover {
  background: #0076e4 !important;
}

.bg-primary-blue:hover h6,
.bg-primary-blue:hover h5 {
  color: #fff !important;
}

.bg-primary-blue:hover p {
  color: #fff !important;
}

.bg-primary-blue:hover .card-footer,
.bg-primary-blue:hover .card-body {
  background-color: #0076e4 !important;
  border-top: 0.0625rem solid #fff !important;
}

.bg-primary-blue:hover a {
  color: #fff !important;
}

.text-space {
  white-space: nowrap;
}

.gutter-footer {
  padding-left: 4rem;
}

.gutter-footer-new {
  padding-left: 0rem;
}

/* Header */
.header-global,
footer {
  font-family: "Poppins" !important;
}

.affinity-logo {
  display: block;
  height: 40px !important;
}

.affinity-logo-color {
  display: none;
  height: 50px;
}

.font-weight-custom {
  font-weight: 500 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

/* footer */
.social-title {
  font-family: "Poppins" !important;
  font-size: 24px;
}

.social-sub {
  font-family: "Poppins" !important;
  font-size: 20px;
}

.custom-line-height {
  line-height: 1.5 !important;
}

.headerFixed .navbar {
  padding: 0.7rem 1rem !important;
  width: 100%;
  height: 68px;
}

.head-fix .navbar-nav .nav-link {
  font-family: "Poppins" !important;
  font-size: 13px !important;
  color: rgba(0, 32, 96) !important;
  font-weight: 500;
}
.head-fix .navbar-nav .nav-link:hover {
  color: rgba(0, 32, 96);
}
.head-fix {
  position: relative;
  height: 68px;
}
.navbar-affinity .navbar-nav {
  font-family: "Poppins" !important;
}

.navbar-nav .dropdown-menu:before {
  background: #fff0 !important;
}
.parties--img-sm {
  height: 2rem !important;
  width: auto !important;
}
.funds-menu {
  left: -2.5rem !important;
}
.dropdown-menu-custom {
  min-width: 1050px;
  border-radius: 0.3rem;
}

.heading {
  font-size: 13px !important;
  font-weight: 500;
}

.heading a,
.heading .open-account {
  color: #062d97 !important;
}

.heading a:hover,
.heading .open-account:hover {
  color: #ff8e00 !important;
}
.link-subText {
  font-size: 12px !important;
  color: #868e96;
}

.arrow-up {
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.arrow-up.rotate {
  transform: rotate(180deg) !important;
}

.arrow-up.rotate-down {
  transform: rotate(0deg) !important;
}
.portfolio-container {
  display: block;
}
.portfolio-container > .card-deck-portfolio {
  max-width: 1040px;
  padding: 0 15px;
  margin: 0 auto;
}
.portfolio-container > .row {
  max-width: 1040px;
  padding: 0 15px;
  margin: 0 auto;
}

.dropdown-menu-affinity {
  font-family: "Poppins" !important;
  top: 100% !important;
  left: -113px !important;
  background: url("../img/affinity/About-us.webp");
  background-position: right center !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #0076e4 !important;
}

.dropdown-menu-affinity .dropdown-menu-inner {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.dailyNavpu__Table td {
  padding: 8px !important;
  font-size: 12px;
  color: #363636;
}
.dailyNavpu__Table tbody tr:hover {
  cursor: pointer;
}
.dailyNavpu__Table thead tr th {
  color: #f1f1f1;
  background-color: #062d97;
}
.dailyNavpu__Table thead tr th.ant-table-column-sort {
  color: #f1f1f1;
  background-color: #062d97;
}
.dailyNavpu__Table thead tr th.ant-table-column-has-sorters:hover {
  background-color: #062d97;
}
.dropdown-menu-affinity .heading:hover,
.faq-hover:hover {
  color: #ff8e00 !important;
}

.dropdown-menu-formNew .heading:hover,
.login--dropdown-bg .heading:hover {
  color: #ff8e00 !important;
}

.dropdown-menu-affinity .dropdown-menu {
  padding: 0 !important;
}

.dropdown-menu-affinity .dropdown-item {
  padding: 0 !important;
}

.dropdown-menu-affinity .dropdown-item:active {
  text-decoration: none !important;
  background-color: #f6f9fc00 !important;
}

.dropdown-menu-affinity .dropdown-item:hover {
  text-decoration: none !important;
  background-color: #f6f9fc00 !important;
}

.dropdown-menu-affinity .heading {
  text-transform: none !important;
}

/* Forms */
.dropdown-menu-forms {
  font-family: "Poppins" !important;
  top: 100% !important;
  left: -427px !important;
  background-image: url("../img/affinity/forms.webp");
  background-position: top center !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #0076e4 !important;
}

.dropdown-menu-forms .dropdown-menu-inner {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.dropdown-menu-forms .heading {
  text-transform: none !important;
}

.form-pb-lg {
  padding-bottom: 13rem !important;
}

/* Solutions */
.dropdown-menu-solutions {
  font-family: "Poppins" !important;
  top: 100% !important;
  left: -490px !important;
  background-image: url("../img/affinity/solutions.webp");
  background-position: top center !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #0076e4 !important;
}

.dropdown-menu-solutions .dropdown-menu-inner {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.dropdown-menu-solutions .heading {
  text-transform: none !important;
}

.ant-notification {
  z-index: 9999 !important;
}

/* Insights */
.dropdown-menu-insights {
  font-family: "Poppins" !important;
  top: 100% !important;
  left: -220px !important;
  background-image: url("../img/affinity/solutions.webp");
  background-position: top center !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #0076e4 !important;
}

.dropdown-menu-insights .dropdown-menu-inner {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.dropdown-menu-insights .heading {
  text-transform: none !important;
}

/* Forms new */
.dropdown-menu-formNew {
  font-family: "Poppins" !important;
  top: 100% !important;
  left: -348px !important;
  background-image: url("../img/affinity/forms.webp");
  background-position: top center !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #0076e4 !important;
}

.dropdown-menu-formNew .dropdown-menu-inner {
  margin-top: 2rem !important;
  margin-bottom: 4rem !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.dropdown-menu-formNew .heading {
  text-transform: none !important;
}

/* Services */
.dropdown-menu-services {
  font-family: "Poppins" !important;
  top: 100% !important;
  left: -556px !important;
  background-image: url("../img/affinity/Funds.webp");
  background-position: top center !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #0076e4 !important;
}

.dropdown-menu-services .dropdown-menu-inner {
  margin-top: 2rem !important;
  margin-bottom: 4rem !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.dropdown-menu-formNew .heading {
  text-transform: none !important;
}

.insight-pb-lg {
  padding-bottom: 10rem;
}

.headerFixed-sm .login-btn {
  padding: 5px 25px !important;
}

/* Funds */
.dropdown-menu-funds {
  font-family: "Poppins" !important;
  top: 100% !important;
  left: -434px !important;
  background-image: url("../img/affinity/Funds.webp");
  background-position: right center !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #0076e4 !important;
}

.dropdown-menu-funds .dropdown-menu-inner {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.dropdown-menu-funds .heading {
  text-transform: none !important;
}

.shadow-custom {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%) !important;
}

.forms--card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.funds-pb-lg {
  padding-bottom: 1rem !important;
}
.fund-card-image {
  height: 4rem;
}
.navbar-nav .nav-item {
  margin-right: 0 !important;
}

.btn-brand-secondary {
  font-family: "Poppins" !important;
  color: #fff !important;
  background-color: #ff8e00 !important;
  border-color: #ff8e00 !important;
  padding: 6px;
  font-size: 12px;
}
.register-button {
  font-weight: 500;
  text-transform: capitalize;
}
.btn-brand-secondary:hover {
  color: #fff !important;
  background-color: #ee8e18 !important;
  border-color: #ee8e18 !important;
}

.btn-brand-affinity {
  font-family: "Poppins" !important;
  color: #fff !important;
  background-color: #0076e4 !important;
  border-color: #0076e4 !important;
}

.btn-brand-affinity:hover {
  color: #fff !important;
  background-color: #0076e4 !important;
  border-color: #0076e4 !important;
}

/* Footer */
.blue-bg {
  height: 100%;
  background: #062d97 !important;
  /* background: linear-gradient(
    90deg,
    rgba(43, 111, 215, 1) 0%,
    rgba(0, 105, 229, 1) 34%,
    rgba(9, 95, 222, 1) 80%
  ); */
  padding: 20px 0;
}

/* .company--details {
  width: 100vw;
  display: block;
} */

.company--details-title {
  text-align: center;
  color: white;
  font-size: 1.2em;
}

.company--details-licensed {
  text-align: center;
  color: #94c3f8;
  font-size: 0.9em;
  font-weight: 400;
}

.company--details-licensed p {
  font-size: 0.9em;
}
.footer--text a {
  color: white !important;
  font-size: 12px;
}

.footer--text h6 {
  font-weight: 500 !important;
}

.footer--text ul {
  list-style-type: none !important;
}

.footer--text ul li::before {
  /* content: "\2022";
  color: white; */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: 1em;
}

.footer--text a:hover {
  color: #fd9d24 !important;
  transition: 0.3s ease color;
}

.white-bg {
  height: 100%;
  background-color: white;
  display: inline-block;
  padding: 0 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo--copy-rights {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.logo--copy-rights .affinity-logo {
  margin-right: 20px;
}

.logo--copy-rights p {
  color: rgba(0, 32, 96);
  font-size: 10px;
  /* font-size: 0.8em; */
}

.link {
  display: flex;
  justify-content: center;
}

.link-partners {
  display: flex;
  justify-content: right;
  align-items: center;
}

.link-partners a,
.link-partners p {
  color: rgba(0, 32, 96);
  margin-right: 20px;
  font-size: 10px;
  cursor: pointer;
}

.link-partners a:hover,
.link-partners p:hover {
  color: #014ab1;
  transition: 0.3s ease color;
}

.link-partners img {
  height: 25px;
  margin-left: 10px;
}

.aws-text {
  margin-left: 1rem !important;
  margin-top: 1rem !important;
  color: rgba(0, 32, 96);
  font-size: 10px !important;
}

/* Loader */
.loader-container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
  text-align: center;
  line-height: 1.6rem;
}
.loader-container .loader-container-inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 1.5rem;
}
.loader-container small {
  margin: 10px 0 0;
  display: block;
  color: #868e96;
  font-weight: normal;
}
.loader-container h3 {
  text-align: center;
  display: block;
}

.loader-container2 {
  align-items: center;
  display: flex;
  height: 300px;
  justify-content: center;
  width: 1000px;
  text-align: center;
  line-height: 1.6rem;
}
.loader-container2 .loader-container-inner2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 1.5rem;
}
.loader-container2 small {
  margin: 10px 0 0;
  display: block;
  color: #868e96;
  font-weight: normal;
}
.loader-container2 h3 {
  text-align: center;
  display: block;
}

.heading ul {
  list-style: none !important;
}

/* terms */
.terms-wrapper {
  max-height: 500px;
  overflow: auto;
  padding: 0 10px;
  margin: 0 -20px 0 0;
}

.terms-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #f5f5f5 !important;
  border-radius: 10px !important;
}

.terms-wrapper::-webkit-scrollbar {
  width: 6px !important;
  height: 5px !important;
  background-color: #f5f5f5 !important;
}

.terms-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: #767676 !important;
}

.terms-wrapper {
  scroll-behavior: smooth;
}

.terms-header {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  margin: 30px 0 0 0;
}

.terms-title {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 26px !important;
  margin: 30px 0 30px 0;
}

.terms-description {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  margin-top: 10px;
}

.terms-list {
  padding-left: 30px;
}

.terms-list li {
  position: relative;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  color: #000000;
  margin-top: 5px;
  font-weight: 400;
}

.nav-link {
  position: relative;
}

.nav-link-inner--text::before {
  transition: 300ms;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #ee8e18;
}

.nav-link-inner--text::before {
  width: 0%;
  bottom: 7px;
}

.navlink-header:hover > .nav-link-inner--text::before {
  width: calc(100% - 2rem);
}

.navbar-main .navlink-header {
  font-size: 12px !important;
}

.sec-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1rem;
}

.react-bootstrap-table thead {
  position: sticky;
  top: 0;
}

.loading--text {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 16px !important;
  color: #062d97;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch !important;
}

.talkUs-bg {
  background: rgb(67, 167, 232);
  background: linear-gradient(
    90deg,
    rgba(67, 167, 232, 1) 0%,
    rgba(206, 229, 244, 1) 53%,
    rgba(229, 242, 249, 1) 100%
  );
}
.social-wrapper {
  display: flex;
  justify-content: center;
  align-items: start;
}
.col-socials {
  border-left: solid 2px #0000000c;
  border-top: none;
}

.official_partners {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.official_partners > img {
  width: 100%;
  max-width: 200px;
}
.anotherTime_button {
  text-align: center;
  padding: 9.5px;
  font-weight: 600;
}
.anotherTime_button:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  background-color: #dee1e7;
}
/* marquee container */
.marquee-container {
  height: 42px;
  font-family: "Poppins", sans-serif;
  cursor: pointer !important;
  position: sticky !important;
  top: 0;
  z-index: 1000;
  background-color: #dce6f1;
}

.navpu-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navpu-marquee {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.diff-percent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.marquee-img {
  max-width: 100px;
  height: 12px;
  margin-right: 10px;
}

.marquee-title {
  font-size: 10px;
  margin: 0;
  color: #062d97;
}
.marquee-date {
  font-size: 10px;
  margin: 0;
  color: #062d97;
}
.navpu-diff {
  font-size: 10px;
  margin: 0;
  color: #000000;
}

.marquee-wrapper {
  height: 100%;
}
.marquee-wrapper > .ant-skeleton {
  padding: 0 8px;
}
.marquee-increased,
.marquee-decreased {
  font-size: 10px;
}

.marquee-increased {
  color: #00b8a9;
}

.marquee-decreased {
  color: #f6416c;
}

.login-links-sm {
  display: none;
}

.adjust-header-top {
  padding-top: 3rem;
}

.marque-mb {
  display: none;
}

.headerFixed-sm .dropdown-menu.show {
  position: absolute !important;
  top: 45px !important;
  left: -40px !important;
  transform: unset !important;
}

/* medium size laptop */

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .head-trans .navbar-nav .nav-link {
    font-family: "Poppins" !important;
    font-size: 16px !important;
    color: #fff !important;
    font-weight: 500;
  }

  .head-fix .navbar-nav .nav-link {
    font-family: "Poppins" !important;
    font-size: 16px !important;
    color: #fff !important;
    font-weight: 500;
  }

  .gutter-footer {
    padding-left: 5px !important;
  }

  .gutter-footer-new {
    padding-left: 5px !important;
  }
}

@media (max-width: 740px) {
  .marquee-container {
    height: 42px;
  }
  .fund-headSM {
    margin-top: -1.2rem !important;
    padding-left: 1rem !important;
  }
  .fund-card-image {
    height: 2rem;
  }
  .talk-sm-order1 {
    order: 1 !important;
  }
  .talk-sm-order2 {
    order: 2 !important;
  }

  .headerLeft-sm {
    margin-left: 1rem !important;
  }
  .heading-ul {
    padding-left: 1.5rem !important;
  }
  .commulative-si-mobile {
    display: block;
  }
  .commulative-si-full {
    display: none;
  }
  .heading,
  .funds--heading {
    font-size: 13px !important;
  }

  .heading li::marker {
    font-size: 150%;
    content: "\2022" !important;
    color: #062d97 !important;
    width: 1em !important;
    margin-left: -1.2em !important;
    margin-right: 1rem !important;
    margin-top: -1em !important;
    font-weight: 600;
  }

  .hashlink--left {
    padding-left: 2rem !important;
  }

  .dropdown-menu-funds ul li a,
  .dropdown-menu-insights ul li a,
  .dropdown-menu-insights ul li .open-account {
    margin-left: 0.5em !important;
  }

  .started-left {
    margin-left: 3rem !important;
    padding-right: 2rem !important;
  }

  .login-links-sm {
    display: block !important;
  }

  .login-links-lg {
    display: none !important;
  }

  .navbar-collapse {
    width: calc(100% - 0rem) !important;
    height: 100vh !important;
    margin: 0 !important;
    border-radius: 0 !important;
    overflow-y: scroll !important;
  }

  .formHead-bottom {
    margin-bottom: 1rem !important;
    padding-right: 2rem !important;
  }

  .navbar-collapse .navbar-toggler span {
    background: #fff !important;
  }

  .navbar-collapse .navbar-collapse-header {
    border-bottom: 1px solid #bab8b8 !important;
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    background: #0076e4 !important;
  }

  .head-trans .navbar-nav .nav-link {
    font-family: "Poppins" !important;
    font-size: 14px !important;
    color: #fff !important;
    font-weight: 500;
  }

  .head-fix .navbar-nav .nav-link {
    font-family: "Poppins" !important;
    font-size: 14px !important;
    color: #fff;
    font-weight: 500;
  }

  .header-wrapper .icon-shape {
    padding: 14px !important;
  }

  .navbar-nav .nav-link {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .navbar-nav a:hover,
  .head-fix .nav-link:hover,
  .head-trans .nav-link:hover {
    color: #ff8e00 !important;
  }
  .btn-start-sm {
    font-family: "Poppins";
    font-size: 12px;
    padding: 5px 5px;
    height: 100%;
  }

  .footer--policy {
    margin-right: 0 !important;
  }

  .footer--terms {
    margin-right: 0 !important;
    margin-left: 30px !important;
  }

  .sec-sm {
    width: 30px !important;
  }

  .sm--top {
    margin-top: 1rem !important;
  }

  .pl-xs-forms {
    padding-right: 2rem !important;
  }

  .headerFixed-sm .dropdown-menu.show {
    position: absolute !important;
    top: 42px !important;
  }

  .headerFixed-sm .login-btn {
    padding: 5px 25px !important;
  }

  .headerFixed-sm .dropdown-menu {
    min-width: 10rem !important;
    padding: 0rem 0rem !important;
  }

  .headerFixed-sm .headerLeft-sm {
    margin-left: 0 !important;
  }

  .marque-mb {
    display: block !important;
  }

  .marque-desk {
    display: none !important;
  }

  .adjust-header-top {
    padding-top: 2rem !important;
  }
}

@media screen and (min-width: 801px) and (max-width: 1100px) {
  .link-partners {
    display: block;
    text-align: right;
  }

  .link-partners .link {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 800px) {
  .white-bg {
    display: block;
  }

  .logo--copy-rights {
    text-align: center;
    display: block;
    padding-bottom: 5px !important;
  }
  .portfolio-container > .card-deck-portfolio {
    display: flex;
    flex-direction: column;
  }
  .link-partners {
    margin-top: 0px;
    padding-bottom: 20px !important;
    display: block;
    text-align: center;
  }

  .link-partners .link {
    margin-bottom: 20px;
  }

  .link-partners img {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .fami-logo {
    margin-top: 1rem !important;
  }

  .nav-link::before {
    height: 0px !important;
  }
}

@media (max-width: 1199.98px) {
  .navbar-collapse .nav-link {
    color: #fff;
    font-family: "Poppins" !important;
    font-size: 15px !important;
    font-weight: 500;
  }
  .fund-card-image {
    height: 3rem;
  }
  .logo--copy-rights .affinity-logo {
    margin: auto;
    margin-bottom: 1rem;
  }
  .pad--sm {
    padding-top: 1rem !important;
    padding-left: 1rem !important;
    padding-right: 0.5rem !important;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .social-wrapper {
    flex-direction: column;
  }
  .col-socials {
    border-top: solid 2px #0000000c;
    border-left: none;
  }
  .social-title {
    font-size: 18px;
  }
  .contact-links {
    padding-bottom: 1rem !important;
    border-bottom: 1px solid #bab8b8;
  }

  .social-sub {
    font-size: 14px;
  }
  .official_partners > img {
    max-width: 150px;
  }
  .dropdown-menu-affinity,
  .dropdown-menu-insights,
  .dropdown-menu-funds,
  .dropdown-menu-solutions,
  .dropdown-menu-services,
  .dropdown-menu-formNew,
  .login--dropdown-bg {
    background: rgb(224, 233, 243);
    background: linear-gradient(
      180deg,
      rgba(224, 233, 243, 1) 20%,
      rgba(220, 230, 241, 0.9668242296918768) 44%,
      rgba(249, 250, 252, 0.9164040616246498) 80%
    ) !important;
    display: block !important;
    max-height: 0;
    padding: 0;
    overflow: hidden;
    transition: max-height 0.4s linear -0.2s;
  }
  .funds-menu.show,
  .dropdown-menu.show {
    max-height: 100%;
    transition: max-height 0.4s linear;
    min-height: 84px;
  }
  .dropdown.show > a {
    color: #ee8e18 !important;
  }
  .funds-menu {
    display: flex !important;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    padding: 0 1rem;
    border-left: 2px solid #ee8e18;
    border-radius: 0;
    background-color: transparent;
    transition: max-height 0.4s linear -0.2s;
  }
  .dropdown-menu-affinity .dropdown-menu-inner,
  .dropdown-menu-insights .dropdown-menu-inner,
  .dropdown-menu-funds .dropdown-menu-inner,
  .dropdown-menu-solutions .dropdown-menu-inner,
  .dropdown-menu-services .dropdown-menu-inner,
  .dropdown-menu-formNew .dropdown-menu-inner {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .dropdown-menu-affinity .heading {
    font-size: 14px !important;
  }

  .gutter-footer {
    padding-left: 5px !important;
  }

  .gutter-footer-new {
    padding-left: 5px !important;
  }

  .initial-sm {
    white-space: nowrap !important;
  }

  .modal-video-body {
    padding: 1.5rem !important;
  }

  .modal-video-close-btn {
    overflow: visible !important;
    right: 10px !important;
  }

  .modal-video-close-btn::before,
  .modal-video-close-btn::after {
    height: 4px !important;
    background: #fff !important;
  }
  .headerFixed .navbar {
    height: 100px;
  }
}
