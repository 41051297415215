.about-bg {
  background: url("~assets/img/affinity/column-bg.webp") 50% fixed !important;
  background-repeat: no-repeat !important;
  /* background-position: 50% fixed !important; */
  background-size: cover !important;
  z-index: 1 !important;
  overflow: visible;
}

.about--title {
  background: linear-gradient(
    90deg,
    rgba(25, 176, 230, 1) 13%,
    rgba(47, 127, 215, 1) 34%,
    rgba(25, 175, 229, 1) 62%,
    rgba(69, 77, 201, 1) 86%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.about--primaryText {
  font-family: "Montserrat" !important;
  font-size: 45px !important;
  text-align: left !important;
  font-weight: 700 !important;
  line-height: 1.2em;
}

.about--font {
  font-size: 40px;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: center;
}

.about--new {
  font-size: 35px;
  font-family: "Montserrat";
  font-weight: 600;
}

.aboutBanner--title {
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: left;
  margin: 0;
  height: -60px !important;
  /* overflow: hidden !important; */
}

.animateSub--text {
  margin: 0;
  height: -80px !important;
  /* overflow: hidden !important; */
}

.animateSub--p {
  margin: 0;
  height: -140px !important;
  /* overflow: hidden !important; */
}

.about--text {
  font-family: "Poppins" !important;
  text-align: center;
  font-size: 16px;
  color: #062d97;
}

.about--newText {
  font-family: "Poppins" !important;
  font-size: 16px;
  color: #062d97;
}

.aboutBanner--text {
  font-family: "Poppins" !important;
  font-size: 17px;
  color: #062d97;
}

.subAbout--text {
  font-family: "Poppins" !important;
  font-size: 14px;
  color: #062d97;
}

.coreMain--title {
  font-family: "Poppins";
  font-size: 40px;
  font-weight: 700;
}

.core--title {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  /* text-align: left; */
  font-size: 20px;
  color: #062d97;
}

.core--title-text {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  /* text-align: left; */
  font-size: 15px;
  color: #062d97;
}

.img-size {
  width: 5%;
}

.icon-size {
  width: 15%;
}

.text-size {
  width: 70%;
}

.media-img {
  margin-right: -8rem;
}

/* BOD */
.p-bod {
  padding: 2.5rem !important;
}

.text--bod {
  color: #003366 !important;
}

.bod--name {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif !important;
  font-size: 16px !important;
}

.bod--extra {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif !important;
  font-size: 12px !important;
}

.bod--position {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif !important;
  font-size: 16px !important;
}

.btn-about {
  color: lightgray !important;
  font-size: 12px !important;
  background-color: #fff !important;
  border-color: lightgray !important;
  padding: 0.3rem 0.7rem;
}

.bod--card {
  margin-top: 1rem;
  cursor: pointer;
  opacity: 0;
}

.fund--card {
  margin-top: 1rem;
  cursor: pointer;
  opacity: 0;
}

.bod--card:hover {
  transform: scaleX(1.05) scaleY(1.05) !important;
}

.mgmt--card {
  margin-top: 1rem;
  cursor: pointer;
  opacity: 0;
}

.mgmt--card:hover {
  transform: scaleX(1.05) scaleY(1.05) !important;
}

/* Modals */
.announcement .modal-body {
  padding: 0 !important;
}

.announcement .modal-footer {
  padding: 0.5rem !important;
}

.modal-size {
  max-width: 1000px !important;
}

.modal-announce {
  max-width: 500px !important;
}

.modal-payment {
  max-width: 600px !important;
}

.modal-events {
  max-width: 500px !important;
}

.bg-modal-about {
  background-color: #f3f7ff !important;
}

.about-modal-color {
  color: #003366 !important;
}

.about-modal {
  font-family: "Poppins" !important;
}

.about-modal-content {
  font-size: 13px !important;
  color: #003366 !important;
  font-weight: normal !important;
}

.border-top-none {
  border-top: 0 !important;
}

.order-sm-1 {
  order: 2 !important;
}

.banner--pad {
  padding-left: 4rem !important;
  /* padding-right: 4rem !important; */
}

.about-list li::before {
  content: "\2014";
  position: absolute;
  margin-left: -20px;
}

.career-sm-right {
  padding-right: 0;
}

.coreTablet {
  margin-bottom: 3rem;
}

.bod-bottom {
  margin-bottom: 3rem;
  padding-inline: 0.75rem !important;
}

/* Tablet query */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .bod--title {
    margin-top: 2rem !important;
  }

  .deck-tablet {
    margin-bottom: 3rem !important;
  }

  .p-bod {
    padding: 1rem !important;
  }

  .bod--name {
    font-size: 14px !important;
  }

  .chairman-tablet {
    white-space: nowrap !important;
  }

  .chairman-tablet {
    padding-top: 50px !important;
  }

  .ceo-tablet {
    margin-top: 2rem !important;
  }

  .d1-tablet {
    margin-top: 2rem !important;
  }

  .id1-tablet {
    margin-top: 2rem !important;
  }

  .id2-tablet {
    margin-top: 2rem !important;
  }

  .exd2-tablet {
    padding-top: 100px !important;
  }

  /* mgmt */
  .mgmtCard1-tablet {
    margin-bottom: 2rem !important;
  }

  .its-tablet {
    margin-bottom: 1rem !important;
  }

  /* Core values */
  .core--title-text {
    font-size: 14px !important;
  }

  .emphatic-tablet {
    margin-top: 3rem !important;
  }
}

@media (max-width: 740px) {
  .about--titleSM {
    font-size: 30px !important;
  }

  .chairman-sm {
    padding-bottom: 20px !important;
  }

  .about--newText {
    font-size: 15px !important;
    font-weight: 400 !important;
  }

  .aboutBanner--text {
    font-size: 15px !important;
    font-weight: 500 !important;
  }

  .position--sm {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .sectionAbout {
    margin-top: 0 !important;
  }

  .containerSM-about {
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }

  .bannerAbout--bottom {
    margin-bottom: 3rem !important;
  }

  .coreSM--about {
    margin-top: 3rem !important;
  }

  .coreSM--img {
    margin-bottom: 3rem !important;
  }

  .deckCore--sm {
    margin-bottom: 0.4rem !important;
  }

  .mgmt--sm {
    margin-top: 0.4rem !important;
  }

  .subAbout--text {
    font-size: 12px !important;
  }

  .core-align {
    text-align: left !important;
  }

  .coreImg-align {
    margin-left: 0 !important;
  }

  .p-bod {
    padding: 1.5rem !important;
  }

  .bod--title {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .about--new {
    font-size: 30px !important;
    line-height: 1 !important;
  }

  .coreMain--title,
  .boardTitle--animate,
  .mgmt--title {
    font-size: 30px !important;
  }

  .about--company {
    margin-top: 2rem !important;
  }

  .about--subText-sm {
    font-size: 26px !important;
  }

  .about--resetPad {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .about--resetTop {
    margin-top: 0 !important;
  }

  .order-sm-1 {
    order: -1 !important;
  }

  .about--img-sm {
    width: 70% !important;
  }

  .career--img-sm {
    width: 100% !important;
  }

  .career-sm-right {
    padding-right: 15px !important;
  }

  .about--addPad {
    margin-top: 1rem !important;
  }

  .bod-info .dl-height {
    margin-bottom: 0.75rem !important;
  }

  .about-order {
    order: 2 !important;
  }

  .about-video-order {
    order: 1 !important;
    margin-bottom: 2rem !important;
  }

  .coreTablet {
    margin-bottom: 15px !important;
  }

  .mgmt-bottom {
    margin-bottom: 0 !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .about-video-order {
    margin-top: 3rem !important;
  }

  .positioning {
    margin-top: 3rem !important;
  }

  .bannerAbout--bottom {
    margin-bottom: 3rem !important;
  }

  .sectionAbout {
    margin-top: 0 !important;
  }

  .coreTablet {
    margin-bottom: 2rem !important;
  }

  .bod-bottom {
    margin-bottom: 2rem !important;
  }

  .bodTablet-container {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .bod-bg {
    background: url("~assets/img/affinity/column-bg.webp") !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
  }

  .tablet-bod {
    display: block !important;
  }

  .invest-sm-top,
  .redeem-sm-top,
  .fs-sm-top {
    margin-top: 1rem !important;
  }

  .wrapper-top {
    margin-top: 3rem !important;
  }
}
