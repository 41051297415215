.financial--title {
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: left;
  margin: 0;
  height: -60px !important;
  /* overflow: hidden !important; */
}

.financial--font {
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 500;
  color: #062d97;
}

.financial--sub {
  font-size: 22px;
}

.financial-helper {
  font-size: 12px !important;
}

.calculator--main p,
.calculator--main ul,
.calculator--main .ant-form-item-label > label {
  font-size: 12px;
}

.calculator--main .ant-form-item {
  margin-bottom: 5px !important;
}

.calculator--main .ant-collapse-header {
  /* background-color: #f4fcff !important; */
  background: url("~assets/img/affinity/column-bg.webp") 50% fixed !important;
  background-repeat: no-repeat !important;
  /* background-position: 50% fixed !important; */
  background-size: cover !important;
  z-index: 1 !important;
  overflow: visible;
}

.amount--text {
  font-size: 20px !important;
}

.financial-border {
  border: 0.0625rem solid #000 !important;
}

.graph--text {
  font-size: 16px !important;
  text-align: center;
}

#legend-container {
  margin-bottom: 3rem !important;
}

.retirement--text {
  font-size: 20px !important;
  /* margin-left: 3.5rem; */
  margin-top: 1rem;
  margin-bottom: 0 !important;
}

.php--text {
  font-size: 18px;
}

.row-right {
  width: 600px;
  white-space: inherit;
}

.chart--wrapper {
  width: 600px;
}

.adjust--textGraph {
  margin-left: 0;
  margin-right: 1rem;
}

.shortfall--text {
  margin-left: 1rem;
  margin-right: 0;
}

.have--text {
  margin-left: 0;
  margin-right: 3rem;
}

.percent {
  font-size: 25px !important;
  margin-bottom: 0;
  margin-top: -3rem;
}

.card--invert-top {
  margin-top: -3rem;
}

.chart--wrapper canvas {
  height: 180px !important;
}

.near--text {
  font-size: 14px !important;
  line-height: 1.4 !important;
}

.padd--near {
  padding: 1rem !important;
}

@media (max-width: 740px) {
  .padding-sm {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .chart--wrapper canvas {
    width: 400px !important;
  }

  .row-right {
    width: 380px !important;
  }

  .graph--text {
    font-size: 14px !important;
  }

  .php--text {
    font-size: 15px !important;
  }

  .amount--text {
    font-size: 16px !important;
  }

  .have--text {
    margin-right: 1rem !important;
  }

  .shortfall--text {
    margin-left: 0 !important;
  }

  .adjust--textGraph {
    margin-left: 1rem !important;
  }

  .graph-sm {
    padding-left: 0 !important;
  }

  .card--invert-top {
    margin-top: 1rem !important;
  }

  .percent {
    font-size: 14px !important;
    margin-top: -1rem !important;
  }

  .default--sm {
    margin-left: 1rem !important;
  }

  .retirement--text {
    font-size: 16px !important;
  }

  .details--top {
    margin-top: 1rem !important;
  }

  .input-sm {
    width: 360px !important;
  }
}
