.bg-contact {
  background: url("../../../img/affinity/contact.webp");
  background-repeat: no-repeat !important;
  background-position: top center;
  background-size: cover !important;
  overflow: auto !important;
}

.bg-right-contact {
  background: #0076e4;
}

.form-section-contact {
  background: url("../../../img/banner/form-contact.png");
  background-repeat: no-repeat !important;
  background-position: center center;
  background-size: 100% !important;
  overflow: hidden !important;
}

.bg-left-contact {
  background: url("../../../img/banner/map.png");
  background-repeat: no-repeat !important;
  background-position: top center;
  background-size: cover !important;
  overflow: auto !important;
}

.map-wrapper,
.contact-wrapper {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
}

.newSub-text {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  text-align: left;
  font-size: 30px;
}

.contact-wrapper-text {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  text-align: left;
  font-size: 30px;
  color: #fff;
}

.address-contact {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  text-align: left;
  font-size: 20px;
}

.address-text {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  text-align: left;
  font-size: 16px;
}

.contact--title {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  text-align: left;
  font-size: 18px;
  color: #ff8e00;
}

.contact--title-text {
  font-family: "Poppins" !important;
  text-align: left;
  font-size: 16px;
  color: #000000;
}

.newContact--title {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  text-align: left;
  font-size: 18px;
  color: #fff;
}

.newContact--title-text {
  font-family: "Poppins" !important;
  text-align: left;
  font-size: 16px;
  color: #fff;
}

.contactForm--title {
  font-size: 36px;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: left;
}

.newContactForm--title {
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 700;
  text-align: center;
  background: rgb(0, 122, 226);
  background: linear-gradient(
    90deg,
    rgba(0, 122, 226, 1) 0%,
    rgba(3, 37, 76, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.contactForm--sub {
  font-size: 16px;
  font-family: "Poppins";
  text-align: left;
  color: rgba(0, 32, 96);
}

.btn--contact {
  font-family: "Poppins" !important;
  color: #fff !important;
  background: #062d97 !important;
}

.btn--contact:hover {
  background: #04206d !important;
}

.contact-prefix {
  font-size: 16px;
  line-height: 1.7 !important;
}

.helper--text {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  color: #000;
}

.contactUs--subTitle {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #062d97 !important;
}

.contact--text {
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #000000 !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .bg-contact {
    background-position: 15% 75% !important;
  }
}

@media (max-width: 740px) {
  .bg-contact {
    background: url("../../../img/affinity/contact-mobile.webp") !important;
    background-repeat: no-repeat !important;
    background-position: 79% 75% !important;
    background-size: cover !important;
  }

  .hero-container {
    padding-top: 5rem;
  }

  .contactForm--title {
    font-size: 24px !important;
  }

  .contact--title {
    font-size: 15px !important;
    text-align: right !important;
    margin-bottom: 0 !important;
  }

  .contact--title-text {
    font-size: 14px !important;
  }

  .contact--padding {
    padding-top: 15rem !important;
  }

  .order-viber {
    order: 1 !important;
    margin-left: 1rem !important;
  }

  .float-sm {
    float: right !important;
  }

  .order-viberText {
    order: 2;
    text-align: right !important;
  }

  .callUs-top {
    margin-top: 1rem !important;
  }
}
