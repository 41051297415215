.bg-corporate {
  background: rgb(224, 233, 243);
  background: linear-gradient(
    180deg,
    rgba(224, 233, 243, 1) 20%,
    rgba(220, 230, 241, 0.9668242296918768) 40%,
    rgba(249, 250, 252, 0.9164040616246498) 80%
  );
  overflow: hidden !important;
}

.corporate--font {
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: left;
}

.corporate--text {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 400;
}

.corporateCard--title {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
}
.contact_us--button {
  border-radius: 5px !important;
  font-size: 16px;
  background-color: #007ae2 !important;
  color: white !important;
}
.contact_us--button:hover {
  background-color: white !important;
  color: #007ae2 !important;
  transition: all 0.5 ease;
}
