.bg-funds {
  background: rgb(224, 233, 243);
  background: linear-gradient(
    180deg,
    rgba(224, 233, 243, 1) 20%,
    rgba(220, 230, 241, 0.9668242296918768) 40%,
    rgba(249, 250, 252, 0.9164040616246498) 80%
  );
  overflow: hidden !important;
}

.funds--font {
  font-size: 30px;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: left;
}

.funds--span {
  background: linear-gradient(
    90deg,
    rgba(25, 176, 230, 1) 13%,
    rgba(47, 127, 215, 1) 34%,
    rgba(25, 175, 229, 1) 62%,
    rgba(69, 77, 201, 1) 86%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.fundTab {
  font-family: "Poppins" !important;
}

.fund--tab .description p {
  font-size: 15px !important;
}

.fund--tab {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  text-transform: uppercase;
  text-align: center !important;
  margin-bottom: 0 !important;
}

.border--title {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.learn-more {
  font-size: 12px;
  color: #fc760d;
}

.by-class {
  font-family: "Poppins";
  font-size: 12px;
  color: #062d97;
  font-weight: 500;
}

.class-text {
  font-family: "Poppins";
  font-size: 12px;
  color: gray;
  font-weight: 400;
}

.fund--text {
  font-family: "Poppins" !important;
  color: #062d97 !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  font-weight: 400;
}

.fundText-details {
  font-size: 12px !important;
  color: #062d97 !important;
}

.cardFooter-funds {
  font-size: 14px;
}

.fund--title-color {
  /* color: rgba(0, 32, 96); */
  color: #062d97;
}

.fund--secondary {
  color: rgba(237, 125, 49);
}

.fund--btn-reset {
  text-transform: none !important;
}

.fundTab--nav .nav-link {
  color: #ff8e00 !important;
  font-size: 18px;
  font-family: "Poppins" !important;
}

.fundTab--nav .nav-link.active {
  background-color: #062d97 !important;
  color: #fff !important;
  /* color: rgba(0, 32, 96) !important;
  background-color: #f4fcff !important; */
  /* background-color: rgba(180, 199, 231) !important; */
}

.fundTab--nav svg.active {
  color: #fff !important;
  background-color: rgba(180, 199, 231) !important;
}

.fund--icon-color {
  /* color: #005cb1; */
  /* background-color: rgba(24, 144, 255, 0.22); */
  background-color: #fff;
  border: 0.8px solid #0076e4;
}

.fundCard--title {
  font-size: 15px;
  color: #000;
  text-transform: uppercase;
  text-align: center;
}

.card-color--hover {
  background-color: #fff !important;
  transition: all 0.5s ease;
}

.card-color--hover:hover {
  background-color: #062d97 !important;
  border-color: #fff !important;
}

.card-color--hover:hover h6,
.card-color--hover:hover h5 {
  color: #fff !important;
}

.card-color--hover:hover hr {
  border-top: 0.0625rem solid rgb(255, 255, 255) !important;
}

.card-color--hover:hover p {
  color: #fff !important;
}

.card-color--hover:hover .card-footer {
  background-color: #062d97 !important;
  border-top: 0.0625rem solid #fff !important;
}

.card-color--hover:hover a {
  color: #fff !important;
}

/* card footer */
.bg-footer-transparent {
  background-color: #fff !important;
}

.nav-pills .conservative {
  margin-right: 2rem !important;
}

.nav-pills .moderate:not(:last-child) {
  margin-left: 0 !important;
  margin-right: 2rem !important;
}

.nav-pills .aggresive {
  margin-right: 0 !important;
  margin-left: 0rem !important;
}

.by-investor .nav-item {
  padding-right: 0 !important;
}

.fund--mobile {
  display: none;
}

/* Mobile tabs */
/* Tabs Color */
.nav-wrapper .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #062d97 !important;
}

.nav-wrapper .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #062d97 !important;
}

.nav-wrapper .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #ff8e0000 !important;
}

.nav-wrapper .ant-steps-item-wait .ant-steps-item-icon {
  border-color: #062d97 !important;
}

.nav-wrapper
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #062d97 !important;
}

.nav-wrapper
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #062d97 !important;
}

.nav-wrapper
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #062d97 !important;
}

.nav-wrapper
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #062d97 !important;
}

.nav-wrapper .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #062d97 !important;
}

.nav-wrapper
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #062d97 !important;
  color: #fff !important;
}

.nav-wrapper
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: #fff !important;
}

.nav-wrapper
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #062d97;
}

.nav-wrapper
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #c5c3c3;
}

/* New Our Funds */
.ourFund--floatingCard {
  border: 0rem solid rgb(0 0 0 / 0%) !important;
}

.sm-px {
  padding-left: 3rem;
  padding-right: 3rem;
}

.fund-container .card {
  background: transparent !important;
  border: 0px solid transparent !important;
}

.fund-container .card-header {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: #000;
  background: transparent !important;
  padding: 0.5rem 0rem !important;
}

.fund-container .card-body {
  background: #fff !important;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%) !important;
}

.time-type {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.time-desc {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}

.mutual-text {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #000;
  line-height: 1.5 !important;
  cursor: pointer;
}

.list-link {
  position: relative;
}

.list-link::before {
  transition: 300ms;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #ee8e18;
}

.list-type::before {
  width: 0%;
  bottom: 0px;
}

.list-type:hover::before {
  width: 40%;
}

.list-link:hover {
  color: #062d97 !important;
}

.fluid-lg {
  padding-left: 6rem;
  padding-right: 6rem;
}

.text-back {
  margin-bottom: 1rem;
}

.funds--top {
  font-size: 14px;
}

.w-text {
  width: 75%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .float-tablet-none {
    float: none !important;
  }

  .clearfix-block {
    display: block !important;
  }

  .fund--text {
    margin-bottom: 0 !important;
  }

  .mm-tablet {
    margin-bottom: 2rem !important;
  }

  .pbf-tablet {
    margin-bottom: 0.7rem !important;
  }

  .ph-tablet {
    margin-bottom: 1.3rem !important;
  }

  .asia-tablet {
    margin-bottom: 2.7rem !important;
  }

  .dollar-tablet {
    margin-bottom: -0.4rem !important;
  }

  .dollar-bt-tab {
    margin-bottom: 2.5rem !important;
  }

  .ph-bt-tab {
    margin-bottom: 2.5rem !important;
  }

  .agma-tab-bt {
    margin-bottom: 1.3rem !important;
  }

  .aggressive-tablet {
    margin-top: 1rem !important;
  }

  .nav-pills .moderate:not(:last-child) {
    margin-right: 0 !important;
  }

  /* Asset */
  .asset-mm-tab {
    margin-bottom: 1.3rem !important;
  }

  .asset-pbf-tablet {
    margin-bottom: -1.4rem !important;
  }

  .asset-pbf-bt {
    margin-bottom: 1.2rem !important;
  }

  .asset-phf-bt {
    margin-bottom: 1.3rem !important;
  }

  .asset-asia-bt {
    margin-bottom: 2.5rem !important;
  }

  .ph-title-tablet {
    margin-bottom: 3.5rem !important;
  }

  .agma-equity-bt {
    margin-bottom: 1.3rem !important;
  }

  /* Currency */
  .currency-tablet-top {
    margin-top: 3.7rem !important;
  }

  .currency-agma-bt {
    margin-bottom: 4rem !important;
  }

  .currency-mm-bt {
    margin-bottom: 1.5rem !important;
  }

  .currency-psi-top {
    margin-top: -1rem !important;
  }

  .currency-pamiTab-top {
    margin-top: -1.5rem !important;
  }

  .currency-psi-bt {
    margin-bottom: 1.2rem !important;
  }

  .currency-asia-bt {
    margin-bottom: 2.6rem !important;
  }

  /* Time Horizon */
  .timeHorizon-tablet .float-left,
  .timeHorizon-tablet .float-right {
    float: none !important;
  }

  .time-tablet-bt {
    margin-bottom: 1.3rem !important;
  }

  .time-asia-bt {
    margin-bottom: 2.6rem !important;
  }

  .time-dollar-top {
    margin-bottom: -0.3rem !important;
  }

  .time-pf-bt {
    margin-bottom: 2.5rem !important;
  }

  .time-philam-bt {
    margin-bottom: 2.5rem !important;
  }

  .time-gmif-bt {
    margin-bottom: 1.3rem !important;
  }

  .time-psg-bt {
    margin-bottom: 1.5rem !important;
  }
}

@media (max-width: 740px) {
  .fund--desktop {
    display: none !important;
  }

  .fund--mobile {
    display: block !important;
  }

  .steps--smleft {
    margin-left: 5rem !important;
  }

  .steps--smleft-manager {
    margin-left: 1rem !important;
  }

  /* Tabs Color */
  .nav-wrapper .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #062d97 !important;
  }

  .nav-wrapper .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #062d97 !important;
  }

  .nav-wrapper .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #ff8e0000 !important;
  }

  .nav-wrapper .ant-steps-item-wait .ant-steps-item-icon {
    border-color: #062d97 !important;
  }

  .nav-wrapper
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #062d97 !important;
  }

  .nav-wrapper
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #062d97 !important;
  }

  .nav-wrapper
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #062d97 !important;
  }

  .nav-wrapper
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #062d97 !important;
  }

  .nav-wrapper .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #062d97 !important;
  }

  .nav-wrapper
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon {
    background: #062d97 !important;
    color: #fff !important;
  }

  .nav-wrapper
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon
    .ant-steps-icon {
    color: #fff !important;
  }

  .fundText-details {
    font-size: 14px !important;
    font-weight: 400;
  }

  .mt--200 {
    padding-top: 1rem !important;
  }

  .funds--font {
    display: flex !important;
    font-size: 24px !important;
  }

  .mr-sm {
    margin-right: 2rem !important;
  }

  .funds--img {
    width: 100px !important;
  }

  .pb-sm {
    padding-bottom: 1rem !important;
  }

  .pb-sm-fund {
    padding-bottom: 1rem !important;
  }

  .funds--top {
    margin-top: 0.3rem !important;
  }

  .mr-reset {
    margin-right: 2rem !important;
  }

  .sm-flex {
    display: flex !important;
  }

  .nav-pills .conservative {
    margin-right: 0 !important;
  }

  .nav-pills .moderate:not(:last-child) {
    margin-right: 0 !important;
  }

  .bottom-funds {
    margin-bottom: 2rem !important;
  }

  .cardFooter-funds {
    font-size: 14px !important;
  }

  .fundCard-bottom {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .fundCard-padding {
    padding-bottom: 0.5rem !important;
  }

  .fundSM--details {
    margin-bottom: 0.7rem !important;
  }

  .learn-more {
    font-size: 15px !important;
  }

  .order-funds {
    order: 1 !important;
  }

  .order-fundsTitle {
    order: 2 !important;
  }

  .order-fundCurrency {
    order: 3 !important;
  }

  .sm-px {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lotte-sm div {
    width: 300px !important;
    height: 300px !important;
  }

  .sm-ready {
    margin-top: 0.5rem !important;
  }

  .sm-b {
    padding-bottom: 0rem !important;
  }

  /*  */
  .nav-wrapper .ant-steps-item {
    margin-bottom: 0.5rem !important;
  }

  .fluid-lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-back {
    font-size: 13px !important;
    margin-bottom: 0 !important;
  }

  .fund-reset .clearfix::before,
  .fund-reset .clearfix::after {
    display: unset !important;
    content: unset !important;
  }

  .fund-reset .clearfix::after {
    clear: unset !important;
  }

  .bottom-card {
    margin-bottom: 2.5rem !important;
  }

  .w-text {
    width: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .card-fund-tablet {
    margin-bottom: 2rem !important;
  }
  .funds-tablet-top {
    padding-top: 2rem;
  }

  .pb-tablet {
    padding-bottom: 1rem !important;
  }
}
