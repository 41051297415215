.career-bg {
  /* background: url("~assets/img/affinity/column-bg.webp") 50% fixed !important; */
  background: url('~assets/img/affinity/bg-careers.webp') !important;
  background-repeat: no-repeat !important;
  background-position: 10% 20% !important;
  background-size: cover !important;
  z-index: 1 !important;
  overflow: visible;
}

.newCareer-bg {
  background: url('~assets/img/affinity/bg-careers.webp') !important;
  background-repeat: no-repeat !important;
  background-position: 10% 20% !important;
  background-size: cover !important;
  z-index: 1 !important;
  overflow: visible;
}

.bg-part {
  background: rgb(247, 252, 255);
  background: linear-gradient(
    90deg,
    rgba(247, 252, 255, 1) 0%,
    rgba(164, 230, 255, 1) 50%
  );
  padding: 40px;
}

.career-container {
  margin-top: 3rem;
}

.career-part-wrapper {
  margin-top: 4rem;
}

.career-part-wrapper-employee {
  margin-top: 3rem;
}

.careerBanner--title {
  font-size: 40px;
  font-family: 'Montserrat';
  font-weight: 700;
  text-align: left;
  margin: 0;
  height: -60px !important;
}

.career--primaryText {
  font-family: 'Poppins' !important;
  font-size: 16px !important;
  text-align: left !important;
  font-weight: 400 !important;
  line-height: 1.7 !important;
  color: #002060 !important;
}

.careerBanner--text {
  font-family: 'Poppins' !important;
  font-size: 20px;
  color: #002060;
  font-weight: 600 !important;
}

.career-cardTitle {
  font-family: 'Poppins' !important;
  font-size: 26px !important;
  text-align: left !important;
  font-weight: 600 !important;
  line-height: 1.5 !important ;
}

.career-cardText {
  font-family: 'Poppins' !important;
  font-size: 18px !important;
  text-align: left !important;
  font-weight: 500 !important;
  line-height: 1.5 !important ;
}

.career-part-title {
  font-family: 'Poppins' !important;
  font-size: 22px;
  color: #002060;
  font-weight: 600 !important;
}

.career-part-text {
  font-family: 'Poppins' !important;
  font-size: 16px !important;
  text-align: left !important;
  font-weight: 400 !important;
  line-height: 1.7 !important;
  color: #002060 !important;
}

.part-title {
  font-family: 'Poppins' !important;
  font-size: 27px;
  color: #002060;
  font-weight: 600 !important;
}

.part-text {
  font-family: 'Poppins' !important;
  font-size: 16px;
  color: #002060;
  font-weight: 400 !important;
}
.career-text {
  font-family: 'Poppins' !important;
  font-size: 14px;
  color: #002060;
  font-weight: 400 !important;
  margin-top: 3rem;
}

.employee-img {
  max-width: 450px !important;
  height: auto !important;
}

.current--title {
  font-family: 'Poppins' !important;
  font-size: 22px;
  color: #062d97;
  font-weight: 600 !important;
}

.job--title {
  font-family: 'Poppins' !important;
  font-size: 16px;
  color: #002060;
  font-weight: 600 !important;
}

.back-title {
  font-family: 'Poppins' !important;
  font-size: 16px;
  color: #007ae2;
  font-weight: 600 !important;
  cursor: pointer;
}

.divider-career {
  height: 5px;
  width: 50%;
  background-color: #ee8e18;
}

.career-card ul {
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 400;
  line-height: 1.3 !important ;
}

.career-btn {
  background: rgb(28, 145, 238) !important;
  background: linear-gradient(
    90deg,
    rgba(28, 145, 238, 1) 0%,
    rgba(0, 122, 226, 1) 48%,
    rgba(93, 184, 240, 1) 100%
  ) !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: 'Poppins' !important;
  border: 0px solid #fff !important;
  padding-left: 3.2rem !important;
  padding-right: 3.2rem !important;
  height: 44px !important;
}

.job-btn {
  background: rgb(28, 145, 238) !important;
  background: linear-gradient(
    90deg,
    rgba(28, 145, 238, 1) 0%,
    rgba(0, 122, 226, 1) 48%,
    rgba(93, 184, 240, 1) 100%
  ) !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600;
  font-family: 'Poppins';
  border: 0px solid #fff !important;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  line-height: 1 !important;
}

.border-job {
  border: 1px solid #007ae2 !important;
}

.form-job .ant-form-item-label {
  padding: 0 0 2px !important;
}

.form-job .ant-form-item {
  margin: 0 0 10px !important;
}

.job-img-center {
  display: block;
  margin-left: auto;
}

.form-job .ant-form-item-explain {
  font-size: 12px !important;
}

.form-job .ant-input {
  background-color: #fff0 !important;
  border: 1px solid #323232;
  border-radius: 5px !important;
}

.form-job .ant-select-selector {
  background-color: #fff0 !important;
  border: 1px solid #323232 !important;
  border-radius: 5px !important;
}

.form-job .ant-form-item-label > label {
  font-size: 13px !important;
}

.apply-text {
  font-family: 'Poppins';
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.formJob--sub {
  font-size: 14px;
  font-family: 'Poppins';
  text-align: left;
  color: rgba(0, 32, 96);
}

.career-desc {
  font-size: 13px;
  font-family: 'Poppins';
  color: #000;
}

.apply-pad {
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 740px) {
  .career-bg {
    background: url('~assets/img/affinity/mobile-career.webp') !important;
    background-repeat: no-repeat !important;
    background-position: 35% 20% !important;
    background-size: cover !important;
    z-index: 1 !important;
    overflow: visible;
  }

  .career-container {
    margin-top: 0 !important;
    padding-bottom: 3rem;
  }

  .current-job .card {
    margin-bottom: 1.5rem !important;
  }

  .careerBanner--title {
    font-size: 36px !important;
  }

  .career-part-sm {
    margin-bottom: 2rem !important;
  }

  .employee-img {
    max-width: 100% !important;
    width: auto !important;
  }

  .part-title {
    font-size: 24px !important;
    margin-bottom: 15px !important;
  }

  .bg-part {
    padding: 20px !important;
  }

  .apply-pad {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .apply-img {
    width: 70% !important;
    margin-left: 0 !important;
    display: unset !important;
    margin-top: 2rem !important;
    margin-bottom: 3rem;
  }
}
