.online--bg {
  background: #f4fcff !important;
}

.online--title {
  font-size: 36px;
  font-family: "Montserrat";
  font-weight: 700;
}

.online--span {
  color: #062d97;
}

.online--text {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
}

.online--steps {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: #062d97;
}

.online--details {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  color: #000;
}

.online--sub {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  color: #062d97;
}

.thead--online {
  font-family: "Poppins";
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.ul-online {
  padding-left: 1.5rem;
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .ul-online-tablet {
    margin-bottom: 1.9rem !important;
  }

  .option-tablet {
    white-space: nowrap !important;
  }

  .optionDetails-tablet {
    margin-left: 1rem !important;
  }
}

@media (max-width: 740px) {
  .online--img {
    width: 29% !important;
  }

  .ul-online {
    padding-left: 1rem !important;
  }

  .online--title {
    font-size: 24px !important;
  }

  .online--sub {
    font-size: 16px !important;
    margin-top: 0.5rem !important;
  }

  .online--details {
    margin-top: 5px;
  }
}
