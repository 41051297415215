/* Insights */
.mobile--insights {
  display: none;
}

.insightsTitle--sm {
  font-size: 16px;
  color: #062d97 !important;
  font-weight: 600 !important;
}

.insightsText--sm {
  font-size: 13px !important;
  color: gray !important;
  font-weight: 400 !important;
}

.mobile--container {
  background: #fff !important;
  margin-top: 20px !important;
}

.play-hover {
  display: inline-block;
  max-width: 320px;
  min-width: 240px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
}

.play-hover:before,
.play-hover:after {
  background-color: rgba(0, 0, 0, 0.4);
  border-top: 32px solid rgba(0, 0, 0, 0.4);
  border-bottom: 32px solid rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  transition: all 0.3s ease;
  z-index: 1;
  opacity: 0;
  transform: scaleY(2);
}

.play-hover img {
  vertical-align: top;
  max-width: 100%;
  backface-visibility: hidden;
}

.play-hover figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1em;
  opacity: 0;
  z-index: 2;
  transition-delay: 0.1s;
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.play-hover:hover:before,
.play-hover:hover:after {
  transform: scale(1);
  opacity: 1;
}

.play-hover:hover:before,
.play-hover:hover::after {
  transform: scale(1);
  opacity: 1;
}

.play-hover:hover figcaption {
  opacity: 1;
}

@media (max-width: 740px) {
  .sm-column--bg {
    background: rgb(224, 233, 243);
    background: linear-gradient(
      180deg,
      rgba(224, 233, 243, 1) 40%,
      rgba(220, 230, 241, 0.9668242296918768) 74%,
      rgba(249, 250, 252, 0.9164040616246498) 100%
    ) !important;
  }

  .desktop--insights {
    display: none !important;
  }

  .mobile--insights {
    display: block !important;
  }

  .insights--img {
    width: 110px !important;
    height: 110px !important;
    object-fit: cover !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .insights--content {
    margin-left: 10px !important;
    padding-right: 5px !important;
  }

  .insightsTitle--sm {
    font-size: 15px !important;
  }
}
