.bg--faq {
  background: url("../../../img/affinity/faq-bg.webp");
  background-repeat: no-repeat !important;
  background-position: bottom center !important;
  background-size: cover !important;
}

.bg--menu {
  background: rgb(53, 181, 225) im !important;
  background: linear-gradient(
    90deg,
    rgba(53, 181, 225, 1) 0%,
    rgba(34, 144, 215, 1) 54%,
    rgba(2, 83, 199, 1) 100%
  ) !important;
}

.ant-menu-dark .ant-menu-item {
  color: #fff !important;
  font-weight: 700;
}

.faq--font {
  font-size: 40px;
  font-family: "Montserrat";
  font-weight: 700;
  text-align: center;
}

.faq--menuTitle {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
}

.faq--color {
  color: #0f4ea8;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) {
  color: #fff !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #fff !important;
  color: #ff8e00 !important;
}

.bg-menu.ant-anchor-link-active > .ant-anchor-link-title {
  color: inherit !important;
}

.bg--menu .ant-menu-item a {
  color: inherit !important;
}

.panel--bottom {
  border-bottom: 1px solid #3f4a5a81 !important;
  padding: 0.5rem;
}

.panel--bottom p {
  font-size: 14px;
  font-weight: 400;
}

.border-color {
  border-color: #fff !important;
}

.table--sizeFAQ {
  width: 200px;
}

/* .border-faq-fixed {
  position: fixed !important;
  top: 0 !important;
} */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .bg--menu-tablet {
    font-size: 14px !important;
    padding: 0.5rem !important;
    width: 350px !important;
  }

  .bg--menu-tablet .ant-menu-item {
    padding-left: 10px !important;
    padding-right: 0 !important;
    font-weight: 400 !important;
  }

  .col-tablet {
    max-width: 28% !important;
    flex: 0 0 28% !important;
  }
}

@media (max-width: 740px) {
  .large--view {
    display: none !important;
  }

  .left--reset-faq {
    margin-left: 0 !important;
  }

  .faq-container .ant-collapse-ghost {
    background-color: #fff !important;
  }

  .faq-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .bg--menu {
    width: 100% !important;
  }
  .faq--font {
    font-size: 24px;
  }
  .faq--padding {
    padding: 0.5rem !important;
  }

  .faq-question-sm {
    margin-left: 1rem !important;
  }

  .faqSM--padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .faqRow--sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ant-collapse > .ant-collapse-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .faqReset--left {
    margin-left: 2rem !important;
  }

  .faq-bottom {
    margin-bottom: 3rem !important;
  }
}
