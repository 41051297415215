/* .bg-details {
  background: url("../../../img/affinity/fund-details.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  overflow: hidden !important;
}

.bg-alfmDollar {
  background: url("../../../img/affinity/dollar-details.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  overflow: hidden !important;
}

.bg-alfmMM {
  background: url("../../../img/affinity/mm-details.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  overflow: hidden !important;
}

.bg-psi {
  background: url("../../../img/affinity/psi-details.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  overflow: hidden !important;
} */

.affinity-details {
  background: #062d97ab !important;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden !important;
}

.fundDetails--font {
  font-size: 24px;
  font-family: "Montserrat";
  font-weight: 600;
}

.fundDetails--span {
  color: rgba(0, 32, 96);
  /* background: linear-gradient(
    90deg,
    rgba(25, 176, 230, 1) 13%,
    rgba(47, 127, 215, 1) 34%,
    rgba(25, 175, 229, 1) 62%,
    rgba(69, 77, 201, 1) 86%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent; */
}

.fundDetails--objective {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  color: #8f8f8f;
}

.fundDetails--header {
  font-size: 16px;
  font-family: "Poppins" !important;
  font-weight: 400;
  color: #fff;
}

.fundDetails--date {
  font-size: 12px;
  font-family: "Poppins" !important;
  font-weight: 400;
  color: #fff;
}

.table-header {
  margin-left: 5px;
  margin-right: 5px;
}

.header--padding {
  padding: 0.5rem 1.5rem !important;
}

.parties--padding {
  padding: 1.2rem 1.5rem !important;
}

.agma--modal-text {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
}

.fundDetails--text {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: rgba(0, 32, 96);
}
.fundDetails--text thead tr th {
  min-width: 130px;
}
.fundDetails--text thead tr th:last-child {
  width: 130px;
}
.notice--text {
  font-size: 11px;
  font-family: "Poppins";
  font-weight: 400;
  color: #f5365c;
}

.fundDetails--container .table td,
.fundDetails--container .table thead th {
  padding: 0.5rem;
  vertical-align: middle;
}
.fund--info-sm {
  white-space: nowrap;
}
.fund--parties .table td {
  padding: 1rem !important;
}

.button--back a {
  color: #062d97 !important;
  background: #e0e9f3 !important;
}

.width-th {
  width: 58% !important;
}

.fund--banner .hide-overflow {
  height: 180px !important;
  overflow: scroll !important;
}

.fund--details-radio .ant-radio-button-wrapper {
  border-radius: 16px !important;
  background: rgb(255, 255, 255);
  color: rgb(92, 102, 123);
  border: 0px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 5px 15px !important;
  margin: 0px 2px !important;
  line-height: 22px !important;
  position: unset !important;
}

.fund--details-radio
  .ant-radio-button-wrapper
  .ant-radio-button-wrapper-checked,
.fund--details-radio
  .ant-radio-button-wrapper
  .ant-radio-button-wrapper-checked
  label {
  background: #0071eb !important;
  color: #fff !important;
}

.fund--details-radio .ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
  width: 0 !important;
}

.recharts-surface {
  width: 100% !important;
}

.custom-tooltip {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid #c3c3c3;
  border-radius: 10px;
}

.custom-tooltip .label-tooltip {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}

.custom-tooltip .desc-tooltip {
  font-size: 14px;
  font-weight: 600;
  color: #062d97;
}

.recharts-layer .recharts-cartesian-axis-tick tspan {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #000;
}

.graph--container > .recharts-brush {
  margin-top: 3rem !important;
}

.details-history .recharts-surface {
  width: 100% !important;
}

.btn-fundDetails {
  font-size: 12px !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .fundDetails--font {
    font-size: 24px !important;
  }

  .btn-fundDetails {
    font-size: 12px !important;
    padding: 10px 10px !important;
    white-space: nowrap !important;
  }
}

@media (max-width: 740px) {
  .fundDetails--font {
    font-size: 20px !important;
  }

  .btn-files-sm {
    margin-top: 1.5rem !important;
    float: none !important;
    justify-content: center !important;
  }

  .sm-view {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .parties--sm {
    padding: 1rem !important;
  }

  .parties--img-sm {
    float: right !important;
    height: 4rem !important;
    width: auto !important;
  }

  .io--sm {
    margin-bottom: 1rem !important;
  }

  .fund--info-sm {
    text-align: right !important;
  }

  .fund--info-bottom {
    margin-bottom: 3rem !important;
  }

  .banner-fundTop {
    margin-top: 1rem !important;
  }

  .details-history .recharts-surface {
    width: 100% !important;
  }

  .graph-sm-pad {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .historical--table .table td,
  .historical--table .table thead th {
    padding: 1rem !important;
  }
}
