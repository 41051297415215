.navpu--bg {
  background: url("~assets/img/affinity/column-bg.webp") 50% fixed !important;
  background-repeat: no-repeat !important;
  /* background-position: 50% fixed !important; */
  background-size: cover !important;
  z-index: 1 !important;
  overflow: visible;
}

.navpu--font {
  font-size: 30px !important;
  font-family: "Montserrat" !important;
  text-align: center !important;
}

.navpu--history {
  font-size: 18px !important;
  font-family: "Montserrat" !important;
  text-align: center !important;
}

.navpu--history-sub {
  font-size: 16px !important;
  font-family: "Montserrat" !important;
  text-align: center !important;
}

.navpu--font-sub {
  font-size: 30px !important;
  font-family: "Montserrat" !important;
  text-align: center !important;
}

.navpu--title {
  background: linear-gradient(
    90deg,
    rgba(25, 176, 230, 1) 13%,
    rgba(47, 127, 215, 1) 34%,
    rgba(25, 175, 229, 1) 62%,
    rgba(69, 77, 201, 1) 86%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.navpu--name {
  text-align: left;
  padding-left: 20px !important;
}

/* Table */
.table--navpu,
.react-bootstrap-table {
  font-family: "Poppins" !important;
  color: #000000 !important;
}

.react-bootstrap-table .table {
  color: #000000 !important;
}

.percent-increased {
  color: #00b8a9;
}

.percent-decreased {
  color: #f6416c;
}

.react-bootstrap-table th {
  font-family: "Poppins" !important;
  font-size: 13px !important;
  color: #212529 !important;
  /* background-color: #edf2f600 !important; */
  background-color: #ffffff !important;
  border-color: #b9bbbe !important;
  vertical-align: middle !important;
}

.table--navpu thead th {
  font-family: "Poppins" !important;
  font-size: 13px !important;
  color: #212529 !important;
  /* background-color: #edf2f600 !important; */
  background-color: #ffffff !important;
  border-color: #b9bbbe !important;
  vertical-align: middle !important;
}

.table--navpu td,
.react-bootstrap-table td {
  border-color: #b9bbbe !important;
  font-size: 12px !important;
}

.table--navpu td {
  font-size: 12px !important;
}

.width-column {
  width: 12% !important;
}

.daily-column {
  width: 40% !important;
}

.hide-overflow {
  height: 340px;
  overflow: scroll !important;
}

.text-tableSub {
  font-family: "Poppins";
  font-size: 16x;
  color: #062d97;
  font-weight: 500;
}

.table-hover tbody tr:hover {
  color: #212529 !important;
  background-color: #e4eef8 !important;
  cursor: pointer !important;
}

.navpu--mobile {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none !important;
  position: relative;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  font-family: "Poppins" !important;
  font-size: 16px !important;
  color: #fff;
}

.ant-notification-hook-holder,
.ant-notification-notice {
  width: 447px !important;
  background: #0076e4 !important;
}

.anticon.ant-notification-notice-icon-info,
.ant-notification-notice-with-icon .ant-notification-notice-message,
.ant-notification-notice-close {
  color: #fff !important;
}

.currency {
  width: 5%;
}

.date {
  width: 5%;
}

.navs {
  width: 4%;
}

.si {
  width: 7%;
}

.fund-mgr {
  width: 6%;
}

.border-bottom-1 {
  border-bottom: 0.0625rem solid #dee2e6 !important;
}

/* Navpu new design */
.navpu--head {
  font-size: 12px !important;
  font-family: "Poppins" !important;
  font-weight: 500;
  color: #000000 !important;
}

.navpu--secondary {
  font-size: 11px !important;
  font-family: "Poppins" !important;
  color: #8f8f8f !important;
}

.sm--navpu .card-body {
  padding: 0.7rem !important;
}

.sm--navpu .ant-collapse {
  border: none !important;
  background-color: #fff !important;
}

.sm--navpu .ant-collapse > .ant-collapse-item {
  border: 0.0625rem solid rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%) !important;
  background-color: #e3eef8 !important;
}

.sm--navpu .ant-collapse-content > .ant-collapse-content-box {
  padding: 12px !important;
  background-color: #e3eef8 !important;
}

.sm--navpu .ant-collapse-content {
  border: none !important;
}

.sm--navpu
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .navpu--head {
  color: #000000 !important;
}

.sm--navpu
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .navpu--secondary {
  color: #8f8f8f !important;
}

.history-col {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.navpu--desktop {
  background: #ffffff;
  padding: 1.5rem;
  padding-top: 0;
  border-radius: 5px;
  position: relative;
}
.navpu__container {
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}
.navpu__container > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.navpu__menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}
.navpu__toggle-button {
  position: absolute;
  top: -40px;
  right: 0;
}
.navpu__menu-nav {
  display: flex;
  justify-content: left;
  position: relative;
  width: 100%;
  max-width: 250px;
}

.navpu__menu-item {
  cursor: pointer;
  padding: 1rem;
}

.navpu__menu-item.active {
  color: #062d97; /* Color for active item */
}

.navpu__indicator {
  position: absolute;
  bottom: 0;
  border-radius: 12px;
  left: 0;
  height: 3px;
  background-color: #062d97;
  transition: all 0.3s ease;
}
.navpu__search {
  height: 32px;
  max-width: 250px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.navpu__search:has(
    .ant-input-wrapper .ant-input-affix-wrapper .ant-input:focus
  ) {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.navpu__search > .ant-input-wrapper > .ant-input-affix-wrapper,
.navpu__search > .ant-input-wrapper > .ant-input-group-addon .ant-btn {
  border: none !important;
}
.navpu__search
  > .ant-input-wrapper
  > .ant-input-affix-wrapper:has(.ant-input:focus) {
  box-shadow: none !important;
}
.navpu__search > .ant-input-wrapper > .ant-input-group-addon > .ant-btn {
  height: 30px;
}
.navpu__filter {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  width: 100%;
  margin-bottom: 16px;
}
.navpu__filter .ant-select-selector {
  color: white;
  background-color: #062d97 !important;
  border: none !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.navpu__filter .ant-select-arrow {
  color: white;
}
.navpu__customDropdown {
  width: 100% !important;
  max-width: 150px !important;
  font-weight: 400 !important;
}
.navpu__customDropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 400 !important;
}
.navpu__card {
  background-color: #fff;
  border-radius: 2px;
  padding: 20px;
  width: 100%;
  margin-bottom: 1rem;
  max-width: 800px;
  box-shadow: 0 0px 1px 0 rgb(88 88 88 / 60%);
  transition: box-shadow 0.3s ease;
}

.navpu__card:hover {
  box-shadow: rgba(99, 99, 99, 0.3) 0px 4px 12px 0px;
}
.navpu__card-header-title {
  font-size: 14px;
  color: #062d97;
  font-weight: 500;
}
.navpu__card-header-label-wrapper {
  display: flex;
  justify-content: space-between;
}
.navpu__card-header-label {
  color: #8f8f8f;
  font-size: 12px;
}
.navpu__card-content {
  display: flex;
  justify-content: space-around;
}
.navpu__card-content-label {
  font-size: 10px;
  color: #8f8f8f;
}
.navpu__card-content-title {
  font-size: 12px;
  color: #363636;
  font-weight: 500;
}
.navpu__card-content-wrapper {
  width: calc(100% / 3);
  text-align: center;
}
.sm--navpu {
  display: none;
}

.history-col .ant-collapse-content,
.history-col .dateNav {
  color: #000000 !important;
}

@media (max-width: 1250px) {
  .navpu--font-sub {
    font-size: 20px !important;
  }

  .navpu--bg {
    background: #ffffff !important;
  }

  .navpu--mobile {
    display: flex !important;
  }

  .navpu--desktop {
    display: none !important;
    background: #ffffff;
    padding: 2rem;
  }

  .daily-head,
  .ytd-head {
    font-size: 14px;
    font-family: "Poppins";
    color: #062d97;
    text-align: center;
    padding: 1.5rem !important;
    background: rgba(91, 154, 213, 0.28) !important;
  }

  .fund-content {
    width: 45%;
    text-align: center !important;
  }

  .navpu-price-content {
    border-bottom: 1px solid gray !important;
  }

  .ytd-content {
    text-align: center !important;
  }

  .price,
  .yrToDate {
    font-family: "Poppins";
    font-size: 22px !important;
    font-weight: 600 !important;
    color: #062d97;
  }

  .fundName-price {
    font-family: "Poppins";
    font-size: 14px !important;
    color: gray;
  }

  .navpu--padding-sm {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .react-bootstrap-table th,
  .react-bootstrap-table td,
  .table--navpu th,
  .table--navpu td {
    padding: 8px !important;
    vertical-align: middle;
  }

  .currency,
  .date,
  .navs {
    width: fit-content !important;
  }

  .history .table {
    width: 1000px !important;
  }

  .sm-currency {
    width: 10% !important;
  }

  .sm-fund {
    width: 23% !important;
  }

  .sm-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0.0625rem solid rgba(0, 0, 0, 0.05);
    border-radius: 0.25rem;
    padding: 1rem;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
      0 5px 15px rgba(0, 0, 0, 0.07);
  }

  .sm--navpu {
    display: block !important;
  }

  .lg--navpu {
    display: none !important;
  }

  .navpu--font {
    font-size: 24px !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .navpu--font-sub {
    font-size: 16px !important;
  }
}
