.question-wrapper {
  width: 100%;
  min-height: calc(100vh - 90px);
  background-color: #fff;
}

.padding-container {
  padding-left: 4rem;
  padding-right: 4rem;
}

.text-red {
  color: red;
}

.text-black {
  color: black;
}

.left-bg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-image: url("../../../img/affinity/retirement-bg.png");
  background-size: contain;
  min-height: calc(100vh - 100px);
  padding-top: 1rem;
}

.custom-shaped-section {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 3rem;
}

.right-side {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-color: #fff;
  background-image: url("../../../img/affinity/retire-bg-new.png");
  background-size: cover;
  min-height: calc(100vh - 100px);
  background-position: center right;
}

.college-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-color: #fff;
  background-image: url("../../../img/affinity/college-right.png");
  background-size: cover;
  min-height: calc(100vh - 50px);
  background-position: center right;
}

.investPlan-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-color: #fff;
  background-image: url("../../../img/affinity/invest-plan-bg.png");
  background-size: cover;
  min-height: calc(100vh - 50px);
  background-position: center right;
}

.purchase-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-color: #fff;
  background-image: url("../../../img/affinity/purchase-bg-NEW.png");
  background-size: cover;
  min-height: calc(100vh - 50px);
  background-position: center right;
}

.button--back button {
  color: #062d97 !important;
  background: #e0e9f3 !important;
}

.button-container {
  margin-top: 3rem;
}

.back-container {
  padding-left: 8rem;
  padding-right: 8rem;
}

.college-bg-estimated {
  background: #d6e8f4;
}

.retire-bg {
  background: #d6e8f4;
  height: 100%;
}

.retire-bg-estimated {
  background: #fff;
}

.risk-card {
  background: url("../../../img/cjm/risk-card.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.journey-bg {
  background: url("../../../img/cjm/cjm-desk-bg.png");
  background-size: cover;
}

.deficit-text {
  width: 100% !important;
  font-weight: 700 !important;
  color: wheat;
}

.register-bg {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  min-height: calc(100vh - 0px);
  background-repeat: no-repeat;
  background-image: url("../../../img/banner/register-shapes.png");
  background-position: top right;
  background-size: cover !important;
  padding: 0 10px;
  z-index: -1;
}

.invest-plan-bg {
  background: #fff;
  background-size: cover;
}

.kindInvestor-bg {
  background: url("../../../img/cjm/cjm-kind-bg.png");
  background-size: cover;
}

.cisolExist-bg {
  background: url("../../../img/affinity/cisol-talk-bg.png");
  background-size: cover;
}

.financialGoal-bg {
  background: url("../../../img/affinity/goal-bg.png");
  background-size: cover;
}

.bundle-goal {
  background: url("../../../img/affinity/choose-goal-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 100vh; */
}

.scale-goal {
  background: url("../../../img/affinity/scale-goal.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
}

.retirement-bg {
  background: url("../../../img/affinity/retirement-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-bg {
  background: url("../../../img/affinity/custom-goal-bg.png");
  background-size: cover;
}

.create-bg {
  background: url("../../../img/affinity/create-bg.png");
  background-size: cover;
}

.journey-title {
  font-family: "Montserrat";
  font-size: 40px;
  color: #072d97;
  font-weight: 700 !important;
  /* text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); */
  letter-spacing: -1px;
  line-height: 1.2 !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

.journey-title2 {
  font-family: "Montserrat";
  font-size: 40px;
  color: #072d97;
  font-weight: 700 !important;
  margin-top: 10px;
  justify-content: center;
}

.kind-investor-title {
  font-family: "Montserrat";
  font-size: 40px;
  color: #072d97;
  font-weight: 700 !important;
  margin-top: 10px;
  letter-spacing: -1px;
  padding-left: 5rem;
  padding-right: 5rem;
}

.financial-title {
  font-family: "Montserrat";
  font-size: 40px;
  color: #072d97;
  font-weight: 700 !important;
  margin-top: 10px;
  letter-spacing: -1px;
  padding-left: 6rem;
  padding-right: 6rem;
}

.cisol-title {
  font-family: "Montserrat";
  font-size: 40px;
  color: #072d97;
  font-weight: 700 !important;
  margin-top: 10px;
  letter-spacing: -1px;
}

.custom-title {
  font-family: "Montserrat";
  font-size: 40px;
  color: #072d97;
  font-weight: 700 !important;
  margin-top: 10px;
  letter-spacing: -1px;
}

.risk-title {
  font-family: "Montserrat";
  font-size: 30px;
  color: #0076e4;
  font-weight: 700 !important;
}

.goal-title {
  font-family: "Montserrat";
  font-size: 40px;
  color: #03254c;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.goal-title-h1 {
  font-family: "Montserrat";
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 0 !important;
  background: linear-gradient(
    280deg,
    rgba(3, 37, 76, 1) 0%,
    rgba(0, 122, 226, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.retirement-title {
  font-family: "Montserrat";
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 0 !important;
  background: linear-gradient(
    267deg,
    rgba(3, 37, 76, 1) 0%,
    rgba(0, 122, 226, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin-top: 4rem !important;
}

.retirement-title-h1 {
  font-family: "Montserrat";
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 0 !important;
  background: linear-gradient(
    90deg,
    rgba(3, 37, 76, 1) 0%,
    rgba(0, 122, 226, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.college-title1 {
  font-family: "Montserrat";
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 0 !important;
  background: rgb(3, 37, 76);
  background: linear-gradient(
    90deg,
    rgba(3, 37, 76, 1) 12%,
    rgba(0, 122, 226, 1) 50%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.college-title2 {
  font-family: "Montserrat";
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 0 !important;
  color: #007ae2;
}

.college-subText {
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0 !important;
  color: #ff8e00;
  width: 70%;
}

.college-question {
  font-family: "Poppins";
  font-size: 14px;
  margin-bottom: 0 !important;
  color: #000000d9;
}

.graph-container {
  display: flex !important;
  min-width: 100% !important;
  justify-content: start;
}

.graph-text-college {
  margin-top: 10px;
  text-align: center;
  padding-left: 0rem;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  line-height: 1.3 !important;
}

.output-card {
  height: 100%;
}

.college-estimated-text {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #062d97;
  margin-bottom: 5px;
}

.college-estimated-sub {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: #062d97;
}

.college-wrapper label {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.college-wrapper .ant-input,
.college-wrapper .ant-input-number-input {
  font-family: "Poppins";
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #000 !important;
}

.risk-goal-text {
  font-family: "Poppins";
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000 !important;
}

.your-goal-text {
  font-size: 20px !important;
  font-weight: 800;
  color: #ff8e00;
}

.college-wrapper .ant-input-number-input {
  padding: 6.5px 11px !important;
}

.choose-school-text {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
}

.estimated-amount-college {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #ff8e00;
}

.estimated-amount-text {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.estimated-wrapper {
  margin-top: 1rem;
  padding-bottom: 3rem;
}

.estimated-wrapper .card-body {
  padding: 1rem !important;
}

.college2-vector {
  width: 50%;
  height: 50%;
}

.btn-wrapper-retire {
  margin-top: 5rem;
}

.btn-wrapper-college {
  margin-top: 3rem;
}

.fund-btn-container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.table-data {
  padding: 2px !important;
  margin-right: 5px;
  text-align: right;
  font-size: 14px;
}

.table-year {
  font-size: 12px;
  text-align: center !important;
}

.excess-red {
  color: red !important;
}

.excess-green {
  color: green !important;
}

.excess-black {
  color: black !important;
}

.fund-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.college-fund-bg {
  background: #2a9df4;
  width: 270px;
}

.college-fund-bg2 {
  background: #187bcd;
  width: 270px;
}

.college-fund-bg3 {
  background: #1167b1;
  width: 270px;
}

.college-fund-title {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: 600;
  color: #ff8e00;
  width: 100%;
}

.college-fund-type {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.college-fund-text {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
  color: #fff;
}

.college-sub-title {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.college-fund-btn {
  font-family: "Poppins";
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #062d97 !important;
  border-color: #062d97 !important;
  margin: 0 !important;
}

.second-fund-btn {
  font-family: "Poppins";
  font-size: 13px !important;
  font-weight: 400 !important;
}

.start-plan {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  color: #ff8e00;
  text-align: center;
}

.college-fund-container label {
  font-family: "Poppins";
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #000 !important;
}

.college-fund-container .card-body {
  padding: 0.8rem !important;
}

.h-card {
  height: 70%;
}

.fundType-wrapper {
  padding-left: 5rem;
  padding-right: 5rem;
}

.fundType-fields .ant-input-number-input {
  font-weight: 500 !important;
  color: #000 !important;
  font-size: 13px !important;
}

.fundType-fields .deficit-text .ant-input-number-input:read-only {
  font-weight: 600 !important;
}

.retire-input .ant-form-item-explain-error {
  font-size: 12px !important;
}

.disclaimer-text {
  text-align: left;
  font-size: 9px;
  margin: 0;
}

.card-fv {
  background: #d1e5f4;
  min-height: 100% !important;
}

.form-text-college {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0 !important;
  color: #000;
}

.college-text-plan {
  font-family: "Poppins";
  font-size: 16px;
  margin-bottom: 0 !important;
  color: #062d97;
}

.college-school-text {
  font-family: "Poppins";
  font-size: 14px;
  margin-bottom: 0 !important;
  color: #062d97;
}

.college-school-sub {
  font-family: "Poppins";
  font-size: 14px;
  margin-bottom: 0 !important;
  color: #062d97;
}

.college-subFee {
  font-family: "Poppins";
  font-size: 10px;
  margin-bottom: 0 !important;
  color: #062d97;
}

.college-bg-card {
  background-image: url("../../../img/affinity/bg-school-card.png");
  border: 0px solid #fff !important;
  cursor: pointer;
}

.cell_height_0 {
  height: 0;
  display: none;
}

.cell_height {
  display: block;
}

.college-bg-card .card-footer {
  padding: 0.5rem 0.5rem !important;
}

.retire-text {
  font-family: "Poppins";
  font-size: 10px;
  color: #03254c;
  font-weight: 400;
  font-style: italic;
  margin-bottom: 0 !important;
}

.goal-subText {
  font-family: "Montserrat";
  font-size: 16px;
  color: #0076e4;
  font-weight: 400;
}

.goal-title-plan {
  font-family: "Montserrat";
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0 !important;
}

.goal-text-plan {
  font-family: "Montserrat";
  font-size: 13px;
  color: #000;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.retirement-goal-sub {
  font-family: "Poppins";
  font-size: 20px;
  color: #03254c;
  font-weight: 600;
}

.college-sub {
  font-family: "Poppins";
  font-size: 20px;
  color: #03254c;
  font-weight: 400;
}

.college-calculate {
  font-family: "Poppins";
  font-size: 14px;
  color: #03254c;
  font-weight: 400;
}

.journey-btn {
  font-size: 13px !important;
  background: rgb(94, 155, 240) !important;
  background: linear-gradient(
    90deg,
    rgba(94, 155, 240, 1) 0%,
    rgba(127, 206, 255, 1) 81%
  ) !important;
  border: 0px solid #fff !important;
  color: #fff !important;
}

.journey-btn-secondary {
  font-size: 13px !important;
  background: #fff !important;
  color: #062d97 !important;
}

.journey-btn-dark {
  font-size: 1.2rem !important;
  color: #fff !important;
  padding: 0.7rem;
}

.retirement-btn {
  font-size: 13px !important;
  background: rgb(94, 155, 240) !important;
  background: linear-gradient(
    90deg,
    rgba(94, 155, 240, 1) 0%,
    rgba(127, 206, 255, 1) 81%
  ) !important;
  border: 0px solid #fff !important;
  color: #fff !important;
}

.choose-btn {
  font-size: 12px !important;
  background: #062d97 !important;
  color: #fff !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.scale-text {
  font-family: "Montserrat";
  font-size: 40px;
  color: #072d97;
  font-weight: 700 !important;
  margin-top: 10px;
  letter-spacing: -1px;
}

.scale-label {
  font-family: "Poppins";
  font-size: 12px;
  color: #03254c;
  font-weight: 500;
}

.college-label {
  font-family: "Poppins";
  font-size: 12px;
  color: #03254c;
  font-weight: 500;
}

.college-amount {
  font-size: 20px;
  font-weight: 700;
  color: "#03254c";
  text-decoration: underline;
  text-underline-offset: 10px;
}

.questionaire .ant-form label {
  font-family: "Poppins";
  font-size: 12px !important;
  color: #000 !important;
  font-weight: 500;
}

.questionaire .ant-select,
.questionaire .ant-input {
  font-family: "Poppins";
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #000 !important;
  border: 1px solid #062d97 !important;
  transition: ease-in-out 0.1s !important;
}

.questionaire .ant-input:focus {
  border: 2px solid #062d97 !important;
  box-shadow: 0 0 0 2px rgba(6, 45, 151, 0.1) !important;
  outline: 0 !important;
}

.ant-select span {
  opacity: 1;
}

.questionaire
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #062d97 !important;
  border-right-width: 1px !important;
  box-shadow: 0 0 0 2px rgba(6, 45, 151, 0.1) !important;
  outline: 0 !important;
}

.questionaire
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 29px !important;
}

.questionaire .ant-select-selection-item {
  font-weight: 500 !important;
  font-family: "Poppins";
  font-size: 13px !important;
}

.options {
  font-family: "Poppins" !important;
  font-size: 12px !important;
  color: #000 !important;
  font-weight: 500 !important;
}

.questionaire .ant-select-dropdown-menu-item-group-title {
  font-size: 10px !important;
}

.questionaire-new .ant-form label {
  font-family: "Poppins";
  font-size: 13px !important;
  color: #062d97 !important;
  font-weight: 500;
}

.questionaire-new .ant-select,
.questionaire-new .ant-input {
  font-family: "Poppins";
  font-size: 13px !important;
}

.questionaire-new .options {
  font-family: "Poppins";
  font-size: 13px !important;
}

.questionaire-new .ant-select-dropdown-menu-item-group-title {
  font-size: 13px !important;
}

.scale-text-wrapper {
  position: absolute;
  bottom: 50px;
}

.financial-wrapper {
  margin-top: 4rem;
  padding-left: 8rem;
  padding-right: 8rem;
}

.financial-wrapper .card-body {
  padding: 0.8rem !important;
}

.financial-wrapper .card-footer {
  background-color: #fff !important;
  border-top: 0px solid #fff !important;
  padding-top: 0 !important;
}

.journey-img {
  width: 300px;
}

.form-quest-wrapper {
  margin-top: 5rem;
  padding-left: 3rem;
}

.retiremet-adjust {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.form-retirement {
  margin-top: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
}

.form-college {
  margin-top: 2rem;
  padding-right: 2rem;
}

.form-college .card-body {
  padding: 0.8rem;
}

.school-name {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #062d97;
  margin-bottom: 0;
}

.form-retirement .ant-form-item {
  margin: 0 0 20px !important;
}

.form-college .ant-form-item {
  margin: 0 0 20px !important;
}

.text-banner {
  width: 160px;
  height: 55px;
  padding-left: 1rem;
  vertical-align: middle !important;
}

.custom-goal-container {
  margin-top: 3rem;
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.custom-container {
  margin-top: 3rem;
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.create-container {
  margin-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.custom-goal-wrapper {
  margin-top: 5rem;
}

.custom-goal-wrapper label {
  font-family: "Poppins";
  font-size: 12px !important;
  font-weight: 500;
  color: #000;
  white-space: nowrap;
}

.third-col {
  height: 100%;
}

.custom-goal-wrapper .ant-input {
  font-family: "Poppins";
  font-size: 13px !important;
  font-weight: 500;
  color: #000;
}

.custom-img-top {
  margin-top: 8rem;
}

.investor-img {
  width: 480px;
}

.custom-img-goal {
  width: 650px;
}

.create-img-goal {
  width: 300px;
}

.choose-img-vector {
  width: 360px;
}

.cisol-img {
  width: 250px;
}

.goal-img {
  width: 50px;
}

.financial-img {
  width: 400px;
}

.journey-wrapper {
  margin-top: 3rem;
}

.kind-wrapper {
  margin-top: 1.5rem;
}

.question-container {
  padding-top: 3rem;
}

.profile-wrapper {
  margin-top: 3rem;
}

.journey-btn {
  margin-top: 3rem;
}

.padding-content {
  padding-right: 8rem;
}

.cisol-top {
  margin-top: 6rem;
}

.cisol-content {
  padding-left: 6rem;
  padding-right: 6rem;
}

.financial-warpper .card {
  cursor: pointer;
}

.guide-container {
  width: 400px !important;
}

.guide-container .link {
  color: #0076e4;
  float: right;
  font-size: 12px;
  cursor: pointer;
}

.guide-container p {
  font-size: 12px;
}

.guide-container .guide-next {
  float: right;
  margin: 0 !important;
}

.guide-container .guide-back {
  float: left;
  margin: 0 !important;
}

.guide-blocker {
  background-color: #00000070;
  width: 100%;
  height: 100vh;
  margin-bottom: -100vh;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}

.guided-form {
  position: relative;
  background-color: white;
  padding: 10px;
  z-index: 9999;
}

.profile--form label {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.profile--form .ant-select {
  font-size: 13px !important;
}

.profile--form .ant-select-dropdown-menu-item-group-title {
  font-size: 13px !important;
}

.option-risk {
  font-size: 13px !important;
}

/* fund type */
.fund-type-bg {
  position: fixed;
  background: url("../../../img/affinity/conservative-bg.png");
  background-position: top center;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.fund-type-img {
  width: 70%;
}

.fund-type-font {
  font-family: "Montserrat";
  font-size: 40px;
  font-weight: 700;
}

.amount-table {
  overflow: hidden !important;
}

.fund-type-sub {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  color: #062d97;
}

.sub-type {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: #062d97;
}

.fund-type-name {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 600;
  color: #062d97;
}

.fund-type-desc {
  font-family: "Poppins";
  font-size: 11px;
  font-weight: 400;
  color: #062d97;
}

.fund-type-wrapper .card-body {
  padding: 1rem !important;
}

.type-form-item label {
  margin-right: 20px;
}

.type-form-item {
  width: 100% !important;
}

.table-tooltip .ant-tooltip-inner {
  background-color: #0076e4;
  color: blue;
}

/* brief details */
.brief-title {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 700;
  color: #062d97;
}

.brief-fundName {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.brief-sub {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  color: #8f8f8f;
}

.brief-obj {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.brief-table th {
  background: #0076e4;
  font-family: "Poppins";
  font-size: 14px !important;
  color: #fff;
}

.brief-table td {
  font-family: "Poppins";
  font-size: 12px !important;
  font-weight: 400;
  color: #000;
}

.brief-btn-wrapper {
  margin-top: 3rem;
}

.brief-btn {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
}

.journey-fund .ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9 !important;
}

.table-title {
  font-size: 14px;
}

.amounts-table {
  padding-left: 0 !important;
}

.investment-table-body td {
  padding-bottom: 0;
  border: none;
}

.table-header {
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  padding: 2px !important;
  white-space: break-spaces;
  width: min-content;
}

.investment-table-body .amount {
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  width: min-content;
}

.fundLoader-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  line-height: 1.6rem;
  transition: all 0.5s ease-in-out;
}

.fundLoader-container .fundLoader-container-inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.5rem;
}

.journey-fund .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 13px !important;
}

.fund-title-type {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 32, 96);
}

.fund-lg {
  display: none;
}

.journey-fund .extra-text {
  font-size: 13px !important;
}

.table-journey th {
  font-size: 12px !important;
}

.table-journey td {
  font-size: 10px !important;
  white-space: nowrap;
}

.table-perf-text {
  font-size: 14px !important;
  font-weight: 600;
}

/* Investment Plan */
.invest-plan-forms label {
  font-size: 12px !important;
  color: #000 !important;
  font-weight: 500;
}

.investment-wrapper .ant-input {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #000 !important;
  border: 1px solid #062d97 !important;
  transition: ease-in-out 0.1s !important;
}

.table-title {
  font-size: 14px;
}

.investment-wrapper .ant-input:focus {
  border: 2px solid #062d97 !important;
  box-shadow: 0 0 0 2px rgba(6, 45, 151, 0.1) !important;
  outline: 0 !important;
}

.investPlan-text {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: #062d97;
}

/* retirement plan */
.retire-title {
  font-family: "Montserrat";
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 0 !important;
  background: rgb(0, 122, 226);
  background: linear-gradient(
    90deg,
    rgba(0, 122, 226, 1) 33%,
    rgba(0, 50, 103, 1) 77%,
    rgba(3, 37, 76, 1) 99%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.custom-title {
  font-family: "Montserrat";
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 0 !important;
  background: rgb(0, 122, 226);
  background: linear-gradient(
    90deg,
    rgba(0, 122, 226, 1) 33%,
    rgba(0, 50, 103, 1) 77%,
    rgba(3, 37, 76, 1) 99%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.retire-title2 {
  font-family: "Montserrat";
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 0 !important;
  background: rgb(0, 122, 226);
  background: linear-gradient(
    270deg,
    rgba(0, 122, 226, 1) 16%,
    rgba(0, 50, 103, 1) 66%,
    rgba(3, 37, 76, 1) 99%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.retire-sub-text {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  color: #062d97;
}

.retire-sub {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  color: #062d97;
}

.retire-top {
  margin-top: 3rem;
}

.retirePlan-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-color: #fff;
  background-image: url("../../../img/affinity/retire-plan-bg.png");
  background-size: cover;
  height: 100%;
  background-position: left left;
}

.calculator-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60%;
}

@media only screen and (max-width: 600px) {
  .calculator-wrapper {
    min-height: 70%;
  }
}

.estimated-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.estimated-title {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
}

.estimated-sub {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.estimated-container label {
  font-family: "Poppins";
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #000;
  white-space: nowrap;
  margin-bottom: 0 !important;
}

.estimated-container .ant-form-item {
  margin-bottom: 5px;
}

.estimated-container .ant-form-vertical .ant-form-item-label {
  padding: 0 !important;
}

.estimated-container .ant-input:placeholder-shown {
  font-size: 13px !important;
}

.estimated-btn {
  font-size: 13px !important;
}

.estimated-text {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: #062d97;
}

.journey-fund
  .ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 12px 40px 12px 0px !important;
}

.journey-fund .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: #dce6f1 !important;
}

.journey-fund .table-bordered td {
  border: 0.0625rem solid #838383 !important;
}

.journey-fund .table-bordered th {
  border: 0.0625rem solid #838383 !important;
}

.retire-wrapper-text {
  margin-top: 2rem;
}

.px-risk {
  padding-left: 6rem;
  padding-right: 6rem;
}

.first-question label {
  margin-bottom: 1.4rem !important;
}

.cjm-btn-wrapper {
  margin-top: 2rem;
}

.cjm-btn1 {
  padding-left: 3rem;
  padding-right: 3rem;
  /* margin-right: 3rem; */
}

.cjm-btn2 {
  padding-left: 3rem;
  padding-right: 3rem;
  margin-left: 3rem;
}

.next-text {
  color: #007ae2 !important;
  font-weight: 500;
  border-bottom: solid 2px #007ae2 !important;
}

.cjm-sm {
  display: flex;
  justify-content: center;
}

.cjm-sm2 {
  display: flex;
  justify-content: center;
  bottom: 0 !important;
}

.btn-dark-blue {
  background-color: #007ae2 !important;
  color: white !important;
  border: 1px solid #007ae2;
  font-size: 14px;
  box-shadow: none;
  width: max-content;
  border-radius: 1px;
}

.btn-back-outlined {
  color: #062d97 !important;
  background-color: transparent;
  border: solid 1px #c7c7c7 !important;
  border-radius: 1px;
  font-size: 14px;
  box-shadow: none;
  width: max-content;
}

.btn-back-cjm {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.btn-back-cjm2 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.kind-btn {
  padding-left: 3rem;
  padding-right: 3rem;
  margin-top: 1rem;
}

.kind-text {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.risk-subText {
  font-family: "Montserrat";
  font-size: 24px;
  color: #0076e4;
  font-weight: 700 !important;
}

.riskResult-title {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  color: #062d97;
}

.riskResult-text {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  color: #062d97;
}

.riskResult {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  color: #062d97;
}

.calculate-btn-row {
  padding: 10px;
}

.inputs-container {
  position: relative;
  padding: 10px;
  display: grid;
  gap: 10px;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  /* grid-template-rows: 1fr 1fr; */
}

.invest-item label {
  width: 192.64px;
  height: 37.69px !important;
}

.no-padding {
  padding: 0 !important;
}

.adjust-placeholder .ant-input-number-input::placeholder {
  text-align: right;
}

.adjust-placeholder .ant-input-number-input {
  text-align: right !important;
}

.adjust-placeholder .ant-input-number-handler-wrap {
  visibility: hidden;
}

.riskResult-sub {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  color: #062d97;
}

.graph-left {
  margin-left: 1.5rem;
}

.retire-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.fund-left-card {
  padding-left: 0;
}

.fund-input-sm {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.fund-input-top {
  padding-top: 15px !important;
}

.college-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.college-container {
  min-height: calc(100vh - 7rem);
}

.college-pd {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-left: 3rem; */
  /* padding-right: 3rem; */
}

.plan-content {
  margin-top: 3rem;
}

.plan-form {
  margin-top: 6rem;
}

.invest-form {
  margin-top: 3rem;
}

.form-plan-btn {
  margin-top: 3rem;
}

.fund-type-adjust {
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

.type-sm {
  display: none;
}

/* register */
.reg-vector {
  width: 80% !important;
}

.register-wrapper {
  position: relative;
  display: block;
  padding-left: 3rem;
}

.register-title {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 500;
  color: #0076e4;
}

.register-text {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  color: #0076e4;
}

.register-wrapper label {
  font-family: "Poppins";
  font-size: 12px !important;
  font-weight: 500;
  color: #000;
}

.register-wrapper .ant-input,
.register-wrapper .ant-select {
  font-family: "Poppins";
  font-size: 13px !important;
  font-weight: 500;
  color: #000;
}

.register-wrapper .ant-form-vertical .ant-form-item-label {
  padding: 0 0 3px !important;
}

.register-col {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.social-media-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.social-links {
  position: absolute;
  margin: 0 5px !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.social-container .ant-btn {
  background: rgb(0, 122, 226);
  background: linear-gradient(
    90deg,
    rgba(0, 122, 226, 1) 12%,
    rgba(0, 50, 103, 1) 61%,
    rgba(3, 37, 76, 1) 100%
  );
  color: #fff;
}

.social-container .ant-btn:hover {
  color: #003267;
  border-color: #003267;
}

.social-text-links {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  color: #062d97;
}

.social-container {
  width: 300px;
}

.risk-bottom-type {
  margin-bottom: 1.5rem;
}

.input-lg-type {
  margin-top: 20px !important;
  margin-bottom: 1rem !important;
  padding-bottom: 9px !important;
}

.fundType-input-lg {
  margin-top: 19px;
}

.financial-top {
  margin-top: 1.5rem;
  padding-bottom: 1rem;
}

.stepper-top {
  margin-top: 3rem;
}

.investplan-form {
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.investform-top {
  margin-top: 1rem;
}

.invest-plan-fluid {
  padding-left: 3rem;
  padding-right: 3rem;
}

.invest-plan-wrapper {
  margin-bottom: 3rem;
}

.invest-card-plan {
  margin-bottom: 1rem;
}

.college-form-plan {
  margin-top: 3rem;
  padding-left: 2rem;
}

.start-plan-wrapper .card-body {
  padding: 2rem !important;
}

.start-plan-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.estimated-graph .recharts-label-list {
  font-size: 18px !important;
  font-weight: 500;
}

.college-right-top {
  min-width: 250px;
  width: 100%;
  max-width: 300px;
}

.estimated-form-top {
  margin-top: 1rem;
}

.financial-width {
  width: 100%;
}

.college-banner-text {
  margin-top: 3rem;
}

.college-steps {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.college-school-top {
  margin-top: 3rem;
}

.college-padd-right {
  padding-right: 0;
}

.retire-top-estimated {
  margin-top: 1rem;
}

.retire-graph-top {
  margin-top: 0;
}

@media (max-width: 768px) {
  .arrow-pointer {
    transform: rotate(90deg);
    align-self: center;
    margin-bottom: 20px;
  }

  .table-year {
    font-size: 10px;
  }

  .table-header {
    font-size: 10px !important;
  }

  .investment-table-body .amount {
    font-size: 10px;
  }

  .results-card {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .journey-bg {
    background: url("../../../img/cjm/CJM-new.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 40% 10%;
    height: auto;
    width: 100%;
  }

  .third-col {
    height: max-content !important;
  }

  .kindInvestor-bg {
    background: url("../../../img/cjm/cjm-kind-sm.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: auto;
    width: 100%;
  }

  .retire-sm-bg {
    background: url("../../../img/cjm/cjm-risk-sm.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: auto;
    width: 100%;
  }

  .bundle-goal {
    background-size: cover;
    background-position: center;
  }

  .cjm-sm {
    justify-content: flex-end !important;
  }

  .cjm-sm2 {
    justify-content: flex-start !important;
  }

  .estimated-container .ant-form-item {
    margin-bottom: 1rem !important;
  }

  .retire-top {
    margin-top: 0 !important;
  }

  .padding-content {
    padding-right: 0;
  }

  .right-top {
    margin-top: 0;
  }

  .kind-investor-title {
    font-size: 26px;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .journey-title2,
  .journey-title {
    font-size: 24px;
    letter-spacing: -1px;
    line-height: 1.7;
  }

  .retire-title,
  .retire-title2 {
    font-size: 30px;
  }

  .journey-title {
    margin-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .risk-title {
    font-size: 30px;
  }

  .goal-title {
    font-size: 30px;
  }

  .goal-title-h1 {
    font-size: 32px;
  }

  .goal-title-plan {
    font-size: 18px;
  }

  .goal-text-plan {
    font-size: 14px;
  }

  .choose-btn {
    font-size: 14px !important;
  }

  .college-fund-title {
    font-size: 26px;
  }

  .form-retirement {
    margin-top: 4rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .text-banner {
    width: 115px !important;
    height: 35px !important;
  }

  .investor-img {
    margin-top: 1rem;
    width: 270px;
  }

  .kind-col-padd {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /* .journey-btn {
    margin-top: 1rem;
  } */
  .college-right-top {
    margin-top: 30px;
  }

  .cjm-btn-wrapper {
    margin-top: 1rem;
    padding-bottom: 3rem;
  }

  .cjm-btn1 {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 1rem;
  }

  .cjm-btn2 {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 1rem;
  }

  .kind-btn {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  .journey-wrapper {
    margin-top: 3rem;
  }

  .journey-img {
    width: 200px;
  }

  .px-risk {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .btn-back-cjm {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .btn-back-cjm2 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .financial-wrapper {
    margin-top: 2rem;
    padding-left: 0;
    padding-right: 0;
  }

  .retire-right {
    display: none;
    z-index: -1;
  }

  .retire-wrapper-text {
    margin-top: 1rem;
  }

  .estimated-wrapper {
    margin-top: 1.5rem;
    padding-bottom: 0 !important;
  }

  /* .estimated-graph {
    margin-left: 1rem;
  } */
  /* 
  .estimated-graph .recharts-responsive-container {
    width: 100% !important;
  } */

  .estimated-graph .line {
    width: 100% !important;
  }

  .estimated-graph .recharts-label-list {
    font-size: 16px !important;
  }

  .graph-left {
    margin-left: 0;
  }

  .graph-container {
    justify-content: center;
  }

  .graph-text-college {
    /* padding-right: 1rem; */
    font-size: 14px;
  }

  .retire-flex {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .estimated-amount-college {
    font-size: 16px;
  }

  .estimated-amount-text {
    font-size: 14px;
  }

  .estimated-form-mb {
    margin-bottom: 2rem;
  }

  .retirePlan-right {
    background-image: unset !important;
    height: 450px !important;
  }

  .retire-img-right {
    width: 40% !important;
  }

  .fundType-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .fundType-input .ant-input-sm {
    padding: 4px 7px !important;
  }

  .fundType-input
    .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 16px 7px !important;
  }

  .fund-left-card {
    padding-left: 0;
    padding-right: 0;
  }

  .fund-card-sm {
    margin-left: 0px;
    margin-right: 0px;
  }

  /* .fund-col-sm {
    padding-left: 0;
    padding-right: 0;
  } */

  .fund-input-sm {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .fund-input-top {
    padding-top: 0 !important;
  }

  .fund-btn-col {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .form-college {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 2rem;
  }

  .college-flex {
    flex-direction: column;
    gap: 0px;
  }

  .college-title1,
  .college-title2 {
    font-size: 30px;
  }

  .college-subText {
    font-size: 20px;
    width: 100%;
  }

  .college-pd {
    padding: 2rem;
  }

  .college-vector-top {
    margin-top: 3rem;
  }

  .btn-wrapper-college {
    margin-top: 3rem;
    padding-bottom: 3rem;
  }

  .btn-wrapper-retire {
    margin-top: 3rem;
    padding-bottom: 3rem;
  }

  .invest-plan-container {
    margin-top: 0rem !important;
  }

  .plan-content {
    margin-top: 0 !important;
  }

  .plan-form {
    margin-top: 2rem;
  }

  .invest-form {
    margin-top: 0;
  }

  .form-plan-btn {
    margin-top: 0rem;
  }

  .fund-type-font {
    font-size: 30px;
  }

  .fund-type-adjust {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .fundtype-top {
    margin-top: 2rem;
  }

  .fund-sm {
    display: none;
  }

  .fund-lg {
    display: block;
  }

  .fund-type-sub {
    font-size: 18px;
    margin-bottom: 0;
  }

  .type-top {
    margin-top: 1rem;
  }

  .sub-type {
    font-size: 15px;
  }

  .type-col {
    padding-left: 0;
    padding-right: 0;
  }

  .type-lg {
    display: none;
  }

  .type-sm {
    display: block;
    margin-bottom: 1.5rem;
  }

  .type-bottom {
    margin-bottom: 3rem;
  }

  .estimated-form-top {
    margin-top: 0 !important;
  }

  .estimated-container label {
    font-size: 14px !important;
    font-weight: 500;
    margin-bottom: 5px !important;
  }

  .financial-width {
    width: unset !important;
  }

  .stepper-top {
    margin-top: 3rem;
  }

  .financial-top {
    padding-bottom: 1.3rem !important;
  }

  .college-form-plan {
    margin-top: 4rem !important;
  }

  .college-banner-text {
    margin-top: 1rem !important;
  }

  .college-school-top {
    margin-top: 1rem !important;
  }

  .college2-vector {
    width: 30% !important;
    height: auto !important;
  }

  .college-padd-right {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .fundType-padd-right {
    padding-left: 0px !important;
  }

  .college-fund-container label {
    font-size: 14px !important;
  }

  .fundType-input-sm {
    margin-top: 0 !important;
  }

  .invest-plan-fluid {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .retire-graph-top {
    margin-top: 1rem !important;
  }
}

@media (min-width: 1920px) {
  .journey-wrapper {
    margin-top: 3rem !important;
  }

  .journey-bg {
    height: 100vh !important;
  }

  .img-relative {
    position: relative !important;
    margin-top: 3rem;
  }

  .lotte-sm img {
    position: normal !important;
    bottom: 0px !important;
    width: auto !important;
    height: 80vh !important;
  }

  .padding-content {
    display: flex;
    flex-direction: column;
    margin-top: 12rem;
  }

  .bundle-goal {
    background-size: cover !important;
    height: calc(100vh + 10%) !important;
  }

  .financial-wrapper {
    margin-top: 10rem !important;
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .goal-title-plan {
    font-size: 16px !important;
  }

  .goal-text-plan {
    font-size: 14px !important;
  }

  .choose-btn {
    font-size: 14px !important;
  }

  .retiremet-adjust {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .form-retirement {
    margin-top: 8rem;
    margin-bottom: 6rem;
    padding-right: 6rem;
    padding-left: 6rem;
  }

  .estimated-container {
    padding-left: 3rem;
  }

  .retirePlan-right {
    height: 100vh !important;
  }

  .retire-top {
    margin-top: 7rem !important;
  }

  .college-estimated-sub {
    font-size: 20px !important;
  }

  .fund-col-lg {
    height: 100%;
  }

  .college-fund-type {
    font-size: 16px !important;
  }

  .college-fund-text {
    font-size: 13px !important;
  }

  .college-fund-btn {
    font-size: 14px !important;
  }

  .fundType-input-lg {
    margin-top: 19px !important;
  }

  .fund-input-top {
    padding-top: 0 !important;
  }

  .column-lg-type {
    padding-left: 10px;
    padding-right: 10px;
  }

  .college-fund-container label {
    font-size: 12px !important;
  }

  .row-lg-type {
    margin-top: 1rem;
  }

  .row-lg-type2 {
    margin-top: 1.5rem;
  }

  .risk-bottom-type {
    margin-bottom: 2rem !important;
  }

  .inputType-lg {
    margin-top: 1.2rem !important;
  }

  .input-lg-type {
    margin-top: 15px !important;
    margin-bottom: 1rem !important;
  }

  .row-input-type {
    margin-top: 0px !important;
  }

  .fundType-wrapper {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .stepper-top {
    margin-top: 5rem !important;
  }

  .invest-plan-fluid {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .invest-plan-padd {
    padding-left: 0 !important;
  }

  .invest-plan-wrapper {
    margin-top: 3rem;
  }

  .invest-plan-bg {
    background: #fff;
    background-size: cover;
    height: 100vh !important;
  }

  .college-form-plan {
    margin-top: 10rem;
    padding-left: 3rem;
  }

  .college-bg-estimated {
    height: max-content !important;
  }

  /* .college-pd {
    margin-top: 6rem !important;
  } */

  .first-question label {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 1199.98px) {
  .graph-container {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

@media screen and (max-width: 500px) {
  .btn-dark-blue,
  .btn-back-outlined {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  .graph-label {
    font-size: 16px;
    color: red;
  }
}

@media screen and (max-width: 360px) {
  .graph-label {
    font-size: 14px;
    color: red;
  }
}

@media screen and (max-width: 420px) {
  .table-header {
    font-size: 10px !important;
    padding: 2px !important;
    font-weight: 500;
  }

  .start-plan-buttons {
    flex-direction: column;
  }
  .anotherTime_button {
    width: 100%;
  }
  .start-plan-buttons button {
    width: 100%;
    margin-inline: 0 !important;
    margin-block: 5px;
  }
}

@media screen and (min-width: 768px) {
  .first-col {
    padding-left: 0 !important;
  }

  .third-col {
    padding-right: 0 !important;
  }
  .output_column {
    border-left: 0.0625rem solid #e9ecef !important;
  }
}

@media only screen and (max-width: 900px) and (min-width: 768px) {
  .labelA {
    margin-right: 20px;
  }

  .labelB {
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 768px) {
  .inputs-container {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 400px) {
  .invest-item .ant-form-item-row {
    grid-template-rows: 30% 70%;
    gap: 5px;
  }
}

@media screen and (max-width: 1620px) {
  .estimated-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 1199.98px) {
  .estimated-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
